import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from "@material-ui/icons/Edit";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import CustomButton from "../global/CustomButton";
import api from "../../../core/api";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import OptionsChatBot from "./Options";
import NewOptionsChatbot from './NewOptions';
import ConfirmationModal from '../global/ConfirmationModal';
import { useContext } from "react";
import { AuthContext } from "../../../core/contexts/AuthProvider";
import { useTranslation } from 'react-i18next';
import FileModal from "./FileModal";

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { FaPaperclip, FaCloudDownloadAlt } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: theme.palette.background.default
  },

  treeRoot: {
    // flexGrow: 1,
    width: 350,
    // overflowX: 'scroll',
    borderRight: '1px solid #D9E2EF',
    minHeight: 700,
    padding: 20
  },

  stepperRoot: {
    "&": {
      borderRadius: 20,
      color: 'white',
      backgroundColor: '#FFF',
      padding: '20px 10px'
    },
    "& .MuiStepIcon-active": { color: "rgb(97, 206, 247)" },
    "& .MuiStepIcon-completed": { color: "green" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
  },

  textFieldRoot: {
    "& .MuiInputBase-root": {
      background: "rgb(232, 241, 250)"
    }
  },

  textField1: {
    marginRight: theme.spacing(1),
    "& .MuiInputBase-root": {
      // background: "#1d1d1d"
    }
  },
  textField: {
    marginRight: theme.spacing(1),
    "& .MuiInputBase-root": {
      background: "white"
    }
  },
  textFieldOption: {
    width: '100%',
    marginRight: 20,
    marginBottom: 15
  },
  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  greetingMessage: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  custom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconBtn: {
    marginRight: '0px !important',
    marginLeft: 15,
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  messageText: {
    color: 'black'
  },
  stepContentContainer: {
    paddingLeft: 10
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'start',
    width: '100%'
  },
  addOptionLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: '10px 0px',
  },
  optionLabel: {
    padding: '10px 0px',
    textTransform: 'capitalize'
  },
  leftContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  leftControlContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '15px',
    borderBottom: '1px solid #D9E2EF'
  },
  editBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px 5px 10px',
    borderRadius: 5,
    marginRight: 20,
    cursor: 'pointer'
  },
  leftInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    padding: 30
  },
  leftInputControlContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%'
  },
  attachFileContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    paddingLeft: 20
  }
}));

export const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: 'transparent',
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `#61A7DB`,
      color: 'white !important',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  addRoot: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: '#61A7DB',
      color: 'white'
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `#61A7DB`,
      color: 'white',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: '#61A7DB',
      color: 'white'
    },
  },
  content: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  addContent: {
    color: '#61A7DB',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  }
}));

const DialogTitle = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  }
}))(MuiDialogTitle);

const DialogContent = withStyles(theme => ({
  root: {
    padding: 0,
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  }
}))(MuiDialogActions);

const Dialog = withStyles(theme => ({
  root: {
    margin: 0,
  }
}))(MuiDialog);


const NewQueueModal = ({ open, onClose, queueId, onEdit, onShowOldModal }) => {
  const classes = useStyles();
  const treeClasses = useTreeItemStyles();
  const { t } = useTranslation();

  const initialState = {
    name: "",
    color: "#000000",
    greetingMessage: "",
    chatbots: [],
    nonOptionMessage: ""
  };

  const [queue, setQueue] = useState(initialState);
  const [chatbotId, setChatbotId] = React.useState(queueId);
  const [activeStep, setActiveStep] = React.useState(null);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isStepContent, setIsStepContent] = React.useState(true);
  const [isNameEdit, setIsNamedEdit] = React.useState(null);
  const [greetingMessage, setGreetingMessage] = React.useState(queue.greetingMessage);
  const [isGreetingMessageEdit, setGreetingMessageEdit] = React.useState(null);
  const [isFileUploadEdit, setFileUploadEdit] = React.useState(null);
  const [showFileUploadModal, setShowFileUploadModal] = React.useState(false);
  const { user, isRtl } = useContext(AuthContext);
  const [alert, setAlert] = useState(null);

  // new here
  const [handleEdit, setHandleEdit] = useState(false);
  const [optionTitle, setOptionTitle] = useState('');
  const [optionMessage, setOptionMessage] = useState('');
  const [optionFiles, setOptionFiles] = useState('');
  const [nonExistOptionMessage, setNonExistOptionMessage] = useState(queue.nonOptionMessage);
  const [chatbotsId, setChatbotsId] = useState(null);

  const [isChildStep, setIsChildStep] = useState(false);
  const [steps, getSteps] = useState(null);
  const [isStepsLoading, setIsStepsLoading] = useState(false);
  const [childOptionId, setChildOptionId] = useState(null);
  const [isAdd, setIsAdd] = useState(false);

  const [selectedQueueId, setSelectedQueueId] = useState(null);

  useEffect(() => {
    setChatbotId(queueId);
  }, [queueId])

  const QueueSchema = Yup.object().shape({
    chatbots: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().min(2, t('yup.short')).required(t('yup.required')),
        })
      )
      .required(t('yup.friends'))
  });

  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setQueue((prevState) => {
          return { ...prevState, ...data };
        });
        setNonExistOptionMessage(data?.nonOptionMessage);
        setGreetingMessage(data?.greetingMessage);
      } catch (err) {
        console.log(err);
      }
    })();

    return () => {
      setQueue({
        name: "",
        color: "#000000",
        greetingMessage: "",
        chatbots: [],
        nonOptionMessage: ""
      });
    };
  }, [queueId, open]);

  useEffect(() => {
    if (activeStep === isNameEdit) {
      setIsStepContent(false);
    } else {
      setIsStepContent(true);
    }
  }, [isNameEdit, activeStep]);

  const handleClose = () => {
    onClose();
    setAlert(null);
    setIsNamedEdit(null);
    setActiveStep(null);
    setGreetingMessageEdit(null);

    setOptionTitle('');
    setOptionMessage('');
    setOptionFiles('');
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (optionsId) => {
    try {
      setIsStepsLoading(true);
      await api.delete(`/chatbot/${optionsId}`);
      const { data } = await api.get(`/queue/${queueId}`);
      setQueue(data);
      setIsNamedEdit(null);
      setGreetingMessageEdit(null);
      setIsStepsLoading(false);
      setFileUploadEdit(null);
      setIsStepsLoading(false);
      setIsAdd(false)
    } catch (err) {
      setIsStepsLoading(false);
      console.log(err);
    }
  };

  const handleDeleteQueueChild = async (queueId) => {
    try {
      setIsStepsLoading(true);
      await api.delete(`/chatbot/${queueId}`);
      setIsNamedEdit(null);
      setGreetingMessageEdit(null);
      setIsStepsLoading(false);
      setFileUploadEdit(null);
      setIsAdd(false)
      console.log("Queue deleted successfully!");
    } catch (err) {
      setIsStepsLoading(false);
      console.log(err)
    }
    setSelectedQueue(null);
  };

  const handleSaveQueue = async (values) => {
    if (values.chatbots.length === 0) {
      setAlert('Please add the options');
      return;
    }
    values = {
      ...values,
      name: user.id + '-chatbot',
      greetingMessage: '',
      color: "#000000",
    }
    try {
      if (chatbotId) {
        await api.put(`/queue/${chatbotId}`, values);
      } else {
        await api.post("/queue", values);
      }

      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveBot = async (values) => {
    try {
      if (chatbotId) {
        await api.put(`/queue/${chatbotId}`, values);
        const { data } = await api.get(`/queue/${chatbotId}`);

        setQueue(data);
        setChatbotId(data.id);
        setIsNamedEdit(null);
        setGreetingMessageEdit(null);
        setShowFileUploadModal(false);
        setFileUploadEdit(null);
      } else {
        const { data } = await api.post("/queue", values);
        setQueue(data);
        setChatbotId(data.id);
        setIsNamedEdit(null);
        setGreetingMessageEdit(null);
        setShowFileUploadModal(false);
        setFileUploadEdit(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveBotChild = async (values) => {
    try {
      setIsStepsLoading(true);
      if (childOptionId) {
        await api.put(`/chatbot/${childOptionId}`, values);
      } else {
        await api.post("/chatbot", values);
      }
      console.log("Bot saved successfully")
      setIsNamedEdit(null);
      setGreetingMessageEdit(null);
      setShowFileUploadModal(false);
      setFileUploadEdit(null);
      setIsStepsLoading(false);
      setIsAdd(false)
    } catch (err) {
      console.log(err);
      setIsStepsLoading(false);
    }
  }

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={`${t('chatbots.modal.delete')}?`}
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => {
          if (selectedQueueId != null) {
            if (isChildStep) {
              let selectedQueueInfo;
              if (steps.options.length && steps.options[selectedQueueId]) {
                selectedQueueInfo = steps.options[selectedQueueId];
                handleDeleteQueueChild(selectedQueueInfo.id);

              }
            } else {
              let selectedQueueInfo;
              if (queue.chatbots.length && queue.chatbots[selectedQueueId]) {
                selectedQueueInfo = queue.chatbots[selectedQueueId];
                handleDeleteQueue(selectedQueueInfo.id);
              }
            }
          }
        }}
      >
        {t('chatbots.modal.deleteQueueContent')}
      </ConfirmationModal>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={handleClose}
        scroll="paper"
        PaperProps={{
          style: {
            position: 'absolute',
            top: 50
          }
        }}
      >
        <DialogTitle style={{ padding: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
            <WhatsAppIcon style={{ marginRight: '10px', fontSize: '35px' }} />
            <span style={{ fontSize: '24px', fontWeight: 'bolder' }}>
              {queueId
                ? t('chatbots.modal.edit')
                : t('chatbots.modal.add')}
            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContentContainer}>
          <div className="w-[350px] min-h-[700px] py-[20px] border-r border-r-[#D9E2EF]">
            <TreeView
              className='w-full px-[20px] min-h-[450px]'
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {queue.chatbots &&
                queue.chatbots.length > 0 &&
                queue.chatbots.map((info, index) => {
                  return (
                    <TreeItem
                      key={index}
                      nodeId={`${index}-chatbot-step`}
                      label={
                        <Typography className={classes.optionLabel}>
                          {queue.chatbots[index].name}
                        </Typography>
                      }
                      classes={{
                        content: treeClasses.content,
                        root: treeClasses.root
                      }}
                      onClick={() => {
                        setIsChildStep(false);
                        setChatbotsId(index);
                        setOptionTitle(queue.chatbots[index].name || '');
                        setOptionMessage(queue.chatbots[index].greetingMessage || '');
                        setOptionFiles(queue.chatbots[index].file || '');
                        setHandleEdit(false);
                      }}
                    >
                      {queue.chatbots[index] && (
                        <NewOptionsChatbot
                          getChildOptionId={(id) => {
                            setIsAdd(false);
                            setChildOptionId(id)
                            setHandleEdit(false);
                          }}
                          chatBotId={info.id}
                          isStepsLoading={isStepsLoading}
                          getSteps={(data) => getSteps(data)}
                          setChatbotsId={(id) => setChatbotsId(id)}
                          setIsChildStep={() => setIsChildStep(true)}
                          setOptionTitle={(title) => setOptionTitle(title)}
                          setOptionMessage={(message) => setOptionMessage(message)}
                          setOptionFiles={(file) => setOptionFiles(file)}
                          handleAddOption={async (infoId) => {
                            const { data } = await api.get(`/chatbot/${infoId}`);
                            setChildOptionId(infoId);
                            getSteps(data);
                            setIsAdd(true);
                            setIsChildStep(true);
                            setHandleEdit(true);
                            setOptionTitle('');
                            setOptionMessage('');
                            setOptionFiles('');
                          }}
                        />
                      )}
                      <TreeItem
                        nodeId={`${info.id}-a`}
                        label={
                          <Typography className={classes.addOptionLabel}>
                            Add options
                          </Typography>
                        }
                        classes={{
                          content: treeClasses.addContent,
                          root: treeClasses.addRoot
                        }}
                        icon={<AddIcon style={{ color: '' }} />}
                        onClick={async () => {
                          const { data } = await api.get(`/chatbot/${info.id}`);
                          setChildOptionId(info.id);
                          getSteps(data);
                          setIsAdd(true);
                          setIsChildStep(true);
                          setHandleEdit(true);
                          setOptionTitle('');
                          setOptionMessage('');
                          setOptionFiles('');
                        }}
                      />
                    </TreeItem>
                  )
                })}
              <TreeItem
                nodeId={`a`}
                label={
                  <Typography className={classes.addOptionLabel}>
                    Add options
                  </Typography>
                }
                classes={{
                  content: treeClasses.addContent,
                  root: treeClasses.addRoot
                }}
                icon={<AddIcon style={{ color: '' }} />}
                onClick={() => {
                  setIsAdd(true);
                  setIsChildStep(false);
                  setHandleEdit(true);
                  setOptionTitle('');
                  setOptionMessage('');
                  setOptionFiles('');
                }}
              />
            </TreeView>
            <div className="w-full flex flex-col justify-start items-start p-[20px] border-t">
              <TextField
                type="text"
                label={t('chatbots.modal.greetingMessage')}
                variant="outlined"
                multiline
                minRows={5}
                required
                value={greetingMessage || ''}
                onChange={e => setGreetingMessage(e.target.value)}
                className={classes.textFieldOption}
              />
              <TextField
                type="text"
                label={t('chatbots.modal.nonExistOptionMessage')}
                variant="outlined"
                multiline
                minRows={5}
                required
                value={nonExistOptionMessage || ''}
                onChange={e => setNonExistOptionMessage(e.target.value)}
                className={classes.textFieldOption}
              />
              <div className="w-full flex flex-row justify-end items-center">
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.btnWrapper}
                  style={{
                    background: '#61A7DB',
                    color: 'white',
                  }}
                  onClick={async () => {
                    if (nonExistOptionMessage && chatbotId) {
                      await api.put(`/queue/${chatbotId}`, {
                        nonOptionMessage: nonExistOptionMessage,
                        greetingMessage: greetingMessage,
                      });
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div className="relative flex flex-1 flex-col">
            <div className={classes.leftControlContainer}>
              <div
                className={classes.editBtnContainer}
                style={{
                  color: handleEdit ? 'white' : '#263238',
                  background: handleEdit ? '#61A7DB' : '#EEF6FB',
                }}
                onClick={() => setHandleEdit(true)}
              >
                <EditIcon style={{ color: handleEdit ? 'white' : '#74818B', marginRight: 10 }} />
                Edit
              </div>
              <DeleteForeverIcon
                style={{ color: '#74818B', marginRight: 10, cursor: 'pointer' }}
                onClick={() => {
                  setSelectedQueueId(chatbotsId);
                  setConfirmModalOpen(true);
                }}
              />
              <AttachFileIcon
                style={{ color: '#74818B', marginRight: 10, cursor: 'pointer' }}
                onClick={() => {
                  setShowFileUploadModal(true);
                  setFileUploadEdit(chatbotsId);
                }}
              />
            </div>

            <div className={classes.leftInputContainer}>
              <TextField
                type="text"
                label={t('chatbots.modal.optionName')}
                variant="outlined"
                required
                disabled={!handleEdit}
                value={optionTitle || ''}
                onChange={e => setOptionTitle(e.target.value)}
                className={classes.textFieldOption}
              />
              <TextField
                type="text"
                label={t('chatbots.modal.message')}
                variant="outlined"
                multiline
                minRows={5}
                required
                disabled={!handleEdit}
                value={optionMessage || ''}
                onChange={e => setOptionMessage(e.target.value)}
                className={classes.textFieldOption}
              />
              {handleEdit &&
                <div className={classes.leftInputControlContainer}>
                  <Button
                    onClick={() => setHandleEdit(false)}
                    style={{
                      color: '#74818B',
                      border: '1px solid #DADBDD',
                      marginRight: 15
                    }}
                  >
                    {t('chatbots.modal.buttons.cancel')}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.btnWrapper}
                    style={{
                      background: '#61A7DB',
                      color: 'white',
                    }}
                    onClick={() => {
                      if (isChildStep) {
                        if (isAdd) {
                          const curQueues = steps;
                          curQueues.options.push({
                            name: optionTitle,
                            greetingMessage: optionMessage,
                            file: optionFiles
                          });
                          handleSaveBotChild(curQueues);
                        } else {
                          if (chatbotsId !== null) {
                            const curQueues = steps;
                            if (curQueues.options.length && curQueues.options[chatbotsId]) {
                              curQueues.options[chatbotsId].name = optionTitle;
                              curQueues.options[chatbotsId].greetingMessage = optionMessage;
                              handleSaveBotChild(curQueues);
                            }
                          }
                        }
                      } else {
                        if (isAdd) {
                          const curQueues = queue;
                          curQueues.chatbots.push({
                            id: undefined,
                            name: optionTitle,
                            greetingMessage: optionMessage,
                            file: optionFiles
                          });
                          handleSaveBot(curQueues);
                        } else {
                          if (chatbotsId !== null) {
                            const curQueues = queue;
                            if (curQueues.chatbots.length && curQueues.chatbots[chatbotsId]) {
                              curQueues.chatbots[chatbotsId].name = optionTitle;
                              curQueues.chatbots[chatbotsId].greetingMessage = optionMessage;
                              handleSaveBot(curQueues);
                            }
                          }
                        }
                      }
                      setHandleEdit(false);
                    }}
                  >
                    Save
                  </Button>
                </div>
              }
              <div className="grid grid-cols-6 gap-6 mt-8">
                {optionFiles ?
                  (optionFiles.split(';') || []).map((fileName, idx) => {
                    if (idx === optionFiles.split(';').length - 1) return;
                    let fileType = ''
                    let splitedFile = fileName.split('.');
                    let file_type = splitedFile[splitedFile.length - 1];
                    if (['jpeg', 'jpg', 'png', 'svg', 'gif'].includes(file_type && file_type.toLowerCase())) {
                      fileType = 'img';
                    } else {
                      fileType = 'file';
                    }
                    return (
                      <div
                        key={idx}
                        className="flex flex-col justify-start items-center space-y-2 cursor-pointer"
                        onClick={() => {
                          window.open(`${process.env.REACT_APP_API_URL}/public/${fileName}`, '_blank');
                        }}
                      >
                        {fileType === 'img'
                          ? (
                            <img
                              className="h-full shadow rounded-md object-cover"
                              src={`${process.env.REACT_APP_API_URL}/public/${fileName}`}
                              alt="file"
                            />
                          )
                          : (
                            <div className="w-[50px] h-[35px] shadow bg-white rounded-md flex flex-row justify-center items-center">
                              <FaPaperclip className='text-gray-800 text-[20px]' />
                            </div>
                          )
                        }
                        {/* <a
                          href={`${process.env.REACT_APP_API_URL}/public/${fileName}`}
                          target='_blank'
                          className='underline text-[#1d1d1d] text-[12px]'
                        >
                          <p>{fileName}</p>
                        </a> */}
                      </div>
                    )
                  }) :
                  <span className="text-red-400 text-[12px] col-span-6">
                    No attached files on this option
                  </span>
                }
              </div>
            </div>

            <div className="flex flex-row justify-end items-center w-full p-[20px] border-t">
              <Button
                color="primary"
                variant="contained"
                className={classes.btnWrapper}
                style={{
                  background: '#61A7DB',
                  color: 'white',
                  marginRight: '15px'
                }}
                onClick={() => {
                  onShowOldModal();
                }}
              >
                DIAGRAM FLOW VIEW
              </Button>
              <CustomButton
                text="Close"
                onClick={onClose}
                type={'cancel'}
              />
            </div>

            {/* <div className="grid grid-cols-2 px-5 pt-4 pb-20">
              <div className="flex flex-col justify-start items-start space-y-4">
                <p className="text-[12px] text-[#74818B]">Parent Option: </p>
                <div className="flex flex-col justify-start items-start space-y-2 ml-6">
                  <div className="flex flex-row justify-start items-center space-x-4">
                    <p className="text-[14px] font-bold text-[#61A7DB]">Parent_title_option</p>
                    <AiOutlineArrowRight className="text-[#61A7DB]" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start space-y-4">
                <p className="text-[12px] text-[#74818B]">Child Options: </p>
                <div className="flex flex-col justify-start items-start space-y-2 ml-6">
                  <div className="flex flex-row justify-start items-center space-x-4">
                    <p className="text-[14px] font-bold text-[#61A7DB]">Child_title_option</p>
                    <AiOutlineArrowRight className="text-[#61A7DB]" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </DialogContent>
      </Dialog>
      <FileModal
        open={showFileUploadModal}
        isFileUploadEdit={isFileUploadEdit}
        onClose={() => {
          setShowFileUploadModal(false)
          setFileUploadEdit(null);
        }}
        setUploadFileName={(index, fileName) => {
          if (isChildStep) {
            const curQueues = steps;
            if (curQueues.options.length && curQueues.options[index]) {
              curQueues.options[index].file = fileName;
              setOptionFiles(fileName);
              if (!isAdd) {
                handleSaveBotChild(curQueues);
              }
            }
          } else {
            const curQueues = queue;
            if (curQueues.chatbots.length && curQueues.chatbots[index]) {
              curQueues.chatbots[index].file = fileName;
              setOptionFiles(fileName);
              if (!isAdd) {
                handleSaveBot(curQueues);
              }
            }
          }
        }}
      />
    </div>
  );
};

export default NewQueueModal;
