import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useTranslation } from 'react-i18next';
import CustomButton from "../global/CustomButton";
import api from "../../../core/api";
import {
  Chip,
  FormControl,
  Input,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "flex-start",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  actions: {
    padding: '20px 20px'
  },
  formControl: {
    minWidth: 120,
  },
  formControlRoot: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    border: '2px solid lightgray',
    padding: 4,
    borderRadius: '4px',
    "&> div.container": {
      gap: "6px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    "& > div.container > span": {
      backgroundColor: "gray",
      padding: "1px 3px",
      borderRadius: "4px"
    }
  }
}));

const initialState = {
  answer: ""
}

const AnswerModal = ({ open, onClose, answerId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [uploadFileName, setUploadFileName] = useState(null);
  const [answer, setAnswer] = useState(initialState);
  const [questions, setQuestions] = useState([]);
  const [currValue, setCurrValue] = useState("");
  const [progress, setProgress] = useState(1)
  const [dragActive, setDragActive] = useState(false);

  const AnswerSchema = Yup.object().shape({
    answer: Yup.string()
      .min(1, t('yup.short'))
      .max(50, t('yup.long'))
      .required(t('yup.required')),
  });

  useEffect(() => {
    const fetchAnswer = async () => {
      if (!answerId) return;
      try {
        const { data } = await api.get(`/answer/${answerId}`);
        setAnswer(prevState => {
          return { ...prevState, ...data };
        });
        setQuestions(data.question.split(";").filter(item => item !== ""));
      } catch (err) {
        // toastError(err);
        console.log("err during get answer: ", err);
      }
    };

    fetchAnswer();
  }, [answerId, open]);

  const handleClose = () => {
    setProgress(1);
    setUploadFileName(null);
    onClose();
    setAnswer(initialState);
  };

  const handleSaveAnswer = async values => {
    const AnswerData = { ...values };
    if (questions.length === 0) {
      console.log("add the question");
      return;
    }
    let question = '';
    for (let i = 0; i < questions.length; i++) {
      question += questions[i] + ';';
    }
    try {
      if (answerId) {
        await api.put(`/answer/${answerId}`, {
          question: question,
          answer: AnswerData.answer,
          file: uploadFileName
        });
      } else {
        await api.post("/answer", {
          question: question,
          answer: AnswerData.answer,
          file: uploadFileName
        });
      }
    } catch (err) {
      // toastError(err);
      console.log("err during add the answer: ", err);
    }
    reset();
    setCurrValue("");
    handleClose();
  };

  const reset = () => {
    setProgress(1);
    setUploadFileName(null);
    setAnswer('');
    setQuestions([]);
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 && e.target.value) {
      setQuestions((oldState) => [...oldState, e.target.value]);
      setCurrValue("");
    }
  };

  const handleChange = (e) => {
    setCurrValue(e.target.value);
  };

  const handleDelete = (item, index) => {
    let arr = [...questions]
    arr.splice(index, 1)
    setQuestions(arr)
  }

  // file processing related functions
  const handleChangeFile = function (e) {
    e.preventDefault();
    if (e.target.files) {
      handleFile(e.target.files);
    }
  };

  const handleFile = async (files) => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
      console.log(`${i + 1}th file: `, files[i]);
    }

    const result = await api.post(`/answer/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (data) => {
        //@ts-ignore Set the progress value to show the progress bar
        const percent = Math.round((data.loaded * 100) / data.total);
        setProgress(percent);
      },
    });

    let fileNames = '';
    if (result?.data.length) {
      for (let i = 0; i < result.data.length; i++) {
        fileNames += result.data[i].filename + ';';
      }
    }

    setUploadFileName(fileNames);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files) {
      handleFile(e.dataTransfer.files);
    }
  };

  console.log("uploadFileName: ", uploadFileName)

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {answerId
            ? t('answers.modal.edit')
            : t('answers.modal.add')}
        </DialogTitle>
        <Formik
          initialValues={answer}
          enableReinitialize={true}
          validationSchema={AnswerSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveAnswer(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => {
            return <Form>
              <DialogContent dividers>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <div className={"container"}>
                    {questions.map((item, index) => (
                      <Chip key={index} size="small" onDelete={() => handleDelete(item, index)} label={item} />
                    ))}
                  </div>
                  <Input
                    placeholder={t('answers.modal.question')}
                    value={currValue}
                    className="w-full"
                    onChange={handleChange}
                    onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                    onKeyDown={handleKeyUp}
                  />
                </FormControl>
                <Field
                  as={TextField}
                  label={t('answers.modal.answer')}
                  name="answer"
                  error={touched.answer && Boolean(errors.answer)}
                  helperText={touched.answer && errors.answer}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  minRows={5}
                  multiline
                />
                <div
                  className="relative mt-4 flex h-auto min-h-[115px] items-center justify-center p-4
                  cursor-pointer rounded border-[1px] border-textgrey"
                  onDragEnter={handleDrag}
                >
                  <label
                    id='label-file-upload'
                    htmlFor='input-file-upload'
                    className={`relative mb-0 flex h-[100px] w-[300px] cursor-pointer items-center justify-center ${dragActive ? 'bg-white' : ''
                      }`}
                  >
                    <input
                      className='hidden'
                      ref={inputRef}
                      multiple
                      type='file'
                      id='input-file-upload'
                      onChange={handleChangeFile}
                      accept='image/png, image/gif, image/jpeg, video/mp4, video/x-m4v, video/*, audio/mp3, audio/*;capture=microphone'
                    />
                    {progress > 1 && (
                      <div className='flex w-full flex-col'>
                        <p className='text-center text-black'>{progress} %</p>
                        <div className='my-3 w-full rounded-lg border border-[#424867]'>
                          <div
                            className='progress-bar h-2 rounded-lg bg-darkgrey'
                            style={{ width: `${progress}%` }}
                          />
                        </div>
                      </div>
                    )}
                    {progress === 1 && <p className='text-black'>Drop files here to upload</p>}
                  </label>
                  {dragActive && (
                    <div
                      id='drag-file-element'
                      className='absolute top-0 right-0 bottom-0 left-0 h-full w-full rounded'
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    />
                  )}
                </div>
              </DialogContent>
              <DialogActions className={classes.actions}>
                <CustomButton
                  text={t('answers.modal.buttons.cancel')}
                  onClick={handleClose}
                  type={'cancel'}
                  isSubmitting={isSubmitting}
                />
                <CustomButton
                  text={
                    answerId
                      ? t('answers.modal.buttons.edit')
                      : t('answers.modal.buttons.add')
                  }
                  type={'confirm'}
                  isSubmitting={isSubmitting}
                />
              </DialogActions>
            </Form>
          }}
        </Formik>
      </Dialog>
    </div>
  )
}

export default AnswerModal;