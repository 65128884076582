import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useTranslation } from 'react-i18next';
import CustomButton from "../global/CustomButton";
import api from "../../../core/api";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "flex-start",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  actions: {
    padding: '20px 20px'
  },
  formControl: {
    minWidth: 120,
  },
  formControlRoot: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    border: '2px solid lightgray',
    padding: 4,
    borderRadius: '4px',
    "&> div.container": {
      gap: "6px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    "& > div.container > span": {
      backgroundColor: "gray",
      padding: "1px 3px",
      borderRadius: "4px"
    }
  }
}));

const FileModal = ({ 
  open, 
  isFileUploadEdit,
  onClose,
  setUploadFileName
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [progress, setProgress] = useState(1)
  const [dragActive, setDragActive] = useState(false);

  const handleChangeFile = function (e) {
    e.preventDefault();
    if (e.target.files) {
      handleFile(e.target.files);
    }
  };

  const handleFile = async (files) => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
      console.log(`${i + 1}th file: `, files[i]);
    }

    const result = await api.post(`/answer/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (data) => {
        //@ts-ignore Set the progress value to show the progress bar
        const percent = Math.round((data.loaded * 100) / data.total);
        setProgress(percent);
      },
    });

    let fileNames = '';
    if (result?.data.length) {
      for (let i = 0; i < result.data.length; i++) {
        fileNames += result.data[i].filename + ';';
      }
    }
    setProgress(1);
    setUploadFileName(null);
    setUploadFileName(isFileUploadEdit, fileNames);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleClose = () => {
    setProgress(1);
    setUploadFileName(null);
    onClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Upload files
        </DialogTitle>
        <DialogContent dividers>
          <div
            className="relative mt-4 flex h-auto min-h-[115px] items-center justify-center p-4
                  cursor-pointer rounded border-[1px] border-textgrey"
            onDragEnter={handleDrag}
          >
            <label
              id='label-file-upload'
              htmlFor='input-file-upload'
              className={`relative mb-0 flex h-[100px] w-[300px] cursor-pointer items-center justify-center ${dragActive ? 'bg-white' : ''
                }`}
            >
              <input
                className='hidden'
                ref={inputRef}
                multiple
                type='file'
                id='input-file-upload'
                onChange={handleChangeFile}
                accept='image/png, image/gif, image/jpeg, video/mp4, video/x-m4v, video/*, audio/mp3, audio/*;capture=microphone'
              />
              {progress > 1 && (
                <div className='flex w-full flex-col'>
                  <p className='text-center text-black'>{progress} %</p>
                  <div className='my-3 w-full rounded-lg border border-[#424867]'>
                    <div
                      className='progress-bar h-2 rounded-lg bg-darkgrey'
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>
              )}
              {progress === 1 && <p className='text-black'>Drop files here to upload</p>}
            </label>
            {dragActive && (
              <div
                id='drag-file-element'
                className='absolute top-0 right-0 bottom-0 left-0 h-full w-full rounded'
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <CustomButton
            text={t('answers.modal.buttons.cancel')}
            onClick={handleClose}
            type={'cancel'}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default FileModal;