import React from "react";
import { Avatar, CardHeader } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const TicketInfo = ({ contact, ticket, onClick }) => {
	console.log("contact: ", contact);
	const { t } = useTranslation();
	return (
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={<Avatar src={contact?.profilePicUrl} alt="contact_image" />}
			title={`${contact?.name}`}
			subheader={''}
		/>
	);
};

export default TicketInfo;
