import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import QueueSelect from "../global/QueueSelect";
import { useTranslation } from 'react-i18next';
import CustomButton from "../global/CustomButton";

import api from "../../../core/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField1: {
    marginRight: theme.spacing(1),
  },
  multFieldLine: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(4),
    },
  },
  btnWrapper: {
    position: "relative",
  },
  actions: {
    padding: '20px 20px'
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialState = {
    name: "",
    greetingMessage: "",
    farewellMessage: "",
    isDefault: false,
    isMultidevice: true,
  };

  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);

  const SessionSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('yup.short'))
      .max(50, t('yup.long'))
      .required(t('yup.required')),
  });

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}`);
        setWhatsApp(data);

        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);
      } catch (err) {
        console.log("err during get the session: ", err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  console.log("whatsAppId: ", whatsAppId);
  const handleSaveWhatsApp = async (values) => {
    const whatsappData = { ...values, queueIds: selectedQueueIds };
    console.log("whatsAppId: ", whatsAppId);
    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/whatsapp", whatsappData);
      }
      console.log("success to the whatsapp connection");
      handleClose();
    } catch (err) {
      console.log("err during save whatsapp: ", err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {whatsAppId
            ? t('connections.modal.edit')
            : t('connections.modal.add')}
        </DialogTitle>
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={t('connections.modal.name')}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  {/* <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isDefault"
                        checked={values.isDefault}
                      />
                    }
                    label={t('connections.modal.default')}
                  /> */}

                  {/* <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isMultidevice"
                        checked={values.isMultidevice}

                      />
                    }
                    label={t('connections.modal.multiple')}
                  /> */}
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={t('connections.modal.greeting')}
                    type="greetingMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="greetingMessage"
                    error={
                      touched.greetingMessage && Boolean(errors.greetingMessage)
                    }
                    helperText={
                      touched.greetingMessage && errors.greetingMessage
                    }
                    variant="outlined"
                    margin="dense"
                    className={classes.textField1}
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={t('connections.modal.farewell')}
                    type="farewellMessage"
                    multiline
                    minRows={5}
                    fullWidth
                    name="farewellMessage"
                    error={
                      touched.farewellMessage && Boolean(errors.farewellMessage)
                    }
                    helperText={
                      touched.farewellMessage && errors.farewellMessage
                    }
                    variant="outlined"
                    margin="dense"
                    className={classes.textField1}
                  />
                </div>
                <QueueSelect
                  selectedQueueIds={selectedQueueIds}
                  onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                />
              </DialogContent>
              <DialogActions className={classes.actions}>
                <CustomButton
                  text={t('connections.modal.buttons.cancel')}
                  onClick={handleClose}
                  type={'cancel'}
                  isSubmitting={isSubmitting}
                />
                <CustomButton
                  text={
                    whatsAppId
                      ? t('connections.modal.buttons.save')
                      : t('connections.modal.buttons.add')
                  }
                  type={'confirm'}
                  isSubmitting={isSubmitting}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
