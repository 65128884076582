import {
  createTheme,
  responsiveFontSizes,
  makeStyles
} from '@material-ui/core/styles';
import { cyan } from '@material-ui/core/colors';

let ltrTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#61A7DB'
    },
    secondary: cyan
  },
  direction: 'ltr'
});

let rtlTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#61A7DB'
    },
    secondary: cyan
  },
  direction: 'rtl'
});

ltrTheme = responsiveFontSizes(ltrTheme);
rtlTheme = responsiveFontSizes(rtlTheme);

const useStyle = makeStyles((theme) => ({
  root: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "4px",
      height: '4px'
    },
    "*::-webkit-scrollbar-track": {
      background: "#1D1D1D"
    },
    "*::-webkit-scrollbar-thumb": {
      background: "#61A7DB",
      borderRadius: "4px"
    }
  }
}));

export { ltrTheme, rtlTheme, useStyle };