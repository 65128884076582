// components/PricePlanCard.js
import React from 'react';
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check';

const PricePlanCard = ({ plan, onSubscribe,deleteScribe, isSubscribed }) => {
  return (
    <div className="bg-white p-4 relative flex flex-col rounded-lg shadow m-2" style={{ height: '100%' }}>
      {isSubscribed && (
        <span>Your PricePlan</span>
      )}
      {plan.popular && (
        <span className="absolute top-0 right-0 bg-blue-600 text-white text-xs py-1 px-2 rounded-bl rounded-tr">Popular</span>
      )}
      <div className="flex-1 flex flex-col">
        <h2 className="text-xl font-bold text-center mt-2 mb-4">{plan.name}</h2>
        <p className="text-4xl font-bold text-center">SAR {plan.price}<span className="text-lg">/mo</span></p>
        <ul className="list-none pl-0 mb-4 mt-4 flex-1">
          {plan.featureNames.map((feature, index) => (
            <li key={index} className="flex items-center mb-2">
              <CheckIcon className="text-green-500" />
              <span className="ml-2 text-gray-700">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      { isSubscribed == false ? (<Button
        variant="contained"
        color="primary"
        onClick={() => onSubscribe(plan.id)}
        className="mt-auto w-full"
      >
        Choose Plan
      </Button>):(<Button
        variant="contained"
        style={{ backgroundColor: '#b74444', color: 'white' }}
        onClick={() => deleteScribe(plan.id)}
        className="mt-auto w-full"
      >
        Cancel Plan
      </Button>)}

    </div>
  );
};

export default PricePlanCard;
