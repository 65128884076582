import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  }
}));

const CustomButton = ({ text, onClick, type, isSubmitting }) => {
  const classes = useStyles();
  return (
    <button
      className={`rounded-[4px] border-[1px] flex flex-row justify-center items-center px-5 py-1 ${type == 'cancel' ? 'border-[#DADBDD] bg-white text-black' : 'border-[#61A7DB] bg-[#61A7DB] text-white'}`}
      onClick={onClick}
      disabled={isSubmitting}
      type={type == 'confirm' ? 'submit' : 'button'}
    >
      {text}
      {isSubmitting && (
        <CircularProgress
          size={24}
          className={classes.buttonProgress}
        />
      )}
    </button>
  )
}

export default CustomButton;