import openSocket from "socket.io-client";
import APP_CONFIG from '../constants/config';
const { API_BASE_URL } = APP_CONFIG;

function connectToSocket() {
  const token = localStorage.getItem("token");
  const socket = openSocket(API_BASE_URL, {
    query: {
      auth_token: token
    },
  });

  return socket;
}

export default connectToSocket;
