import { createContext, useState, useContext } from 'react';

const UserDataContext = createContext(null);

export const useUserData = () => useContext(UserDataContext);

export const UserDataProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  const saveUserData = (data) => {
    setUserData(data);
  };

  return (
    <UserDataContext.Provider value={{ userData, saveUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};
