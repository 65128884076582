import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";

import { FormControlLabel } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from "@material-ui/icons/Edit";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import MessageIcon from '@material-ui/icons/Message';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Switch from "@material-ui/core/Switch";

import api from "../../../core/api";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import OptionsChatBot from "./Options";
import ConfirmationModal from '../global/ConfirmationModal';
import { useContext } from "react";
import { AuthContext } from "../../../core/contexts/AuthProvider";
import { useTranslation } from 'react-i18next';
import FileModal from "./FileModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: theme.palette.background.default
  },

  stepperRoot: {
    "&": {
      borderRadius: 20,
      color: 'white',
      backgroundColor: '#FFF',
      padding: '30px 20px'
    },
    "& .MuiStepIcon-active": { color: "rgb(97, 206, 247)" },
    "& .MuiStepIcon-completed": { color: "green" },
    "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
  },

  textFieldRoot: {
    "& .MuiInputBase-root": {
      background: "rgb(232, 241, 250)"
    }
  },

  textField1: {
    marginRight: theme.spacing(1),
    "& .MuiInputBase-root": {
      // background: "#1d1d1d"
    }
  },
  textField: {
    marginRight: theme.spacing(1),
    "& .MuiInputBase-root": {
      background: "white"
    }
  },
  textFieldOption: {
    width: '200px',
    marginRight: 20,
  },
  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  greetingMessage: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  custom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconBtn: {
    marginRight: '0px !important',
    marginLeft: 15,
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  messageText: {
    color: 'black'
  }
}));

const DialogTitle = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    // background: 'black',
    // color: 'white',
    // borderBottom: 'none',
    // width: '100%',
    // textAlign: 'center'
  }
}))(MuiDialogTitle);

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    // background: 'black',
    // color: 'white'
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    // background: 'black',
    // color: 'white'
  }
}))(MuiDialogActions);

const Dialog = withStyles(theme => ({
  root: {
    margin: 0,
  }
}))(MuiDialog);


const QueueModal = ({ open, onClose, queueId, onEdit, onShowNewModal }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialState = {
    name: "",
    color: "#000000",
    greetingMessage: "",
    chatbots: [],
  };

  const [queue, setQueue] = useState(initialState);
  const [chatbotId, setChatbotId] = React.useState(queueId);
  const [activeStep, setActiveStep] = React.useState(null);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isStepContent, setIsStepContent] = React.useState(true);
  const [isNameEdit, setIsNamedEdit] = React.useState(null);
  const [isGreetingMessageEdit, setGreetingMessageEdit] = React.useState(null);
  const [isFileUploadEdit, setFileUploadEdit] = React.useState(null);
  const [showFileUploadModal, setShowFileUploadModal] = React.useState(false);
  const { user, isRtl } = useContext(AuthContext);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    setChatbotId(queueId);
  }, [queueId])

  const QueueSchema = Yup.object().shape({
    chatbots: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().min(2, t('yup.short')).required(t('yup.required')),
        })
      )
      .required(t('yup.friends'))
  });

  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setQueue((prevState) => {
          return { ...prevState, ...data };
        });
      } catch (err) {
        console.log(err);
      }
    })();

    return () => {
      setQueue({
        name: "",
        color: "#000000",
        greetingMessage: "",
        chatbots: [],
      });
    };
  }, [queueId, open]);

  useEffect(() => {
    if (activeStep === isNameEdit) {
      setIsStepContent(false);
    } else {
      setIsStepContent(true);
    }
  }, [isNameEdit, activeStep]);

  const handleClose = () => {
    onClose();
    setAlert(null);
    setIsNamedEdit(null);
    setActiveStep(null);
    setGreetingMessageEdit(null);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (optionsId) => {
    try {
      await api.delete(`/chatbot/${optionsId}`);
      const { data } = await api.get(`/queue/${queueId}`);
      setQueue(initialState);
      setQueue(data);
      setIsNamedEdit(null);
      setGreetingMessageEdit(null);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveQueue = async (values) => {
    if (values.chatbots.length === 0) {
      setAlert('Please add the options');
      return;
    }
    values = {
      ...values,
      name: user.id + '-chatbot',
      greetingMessage: '',
      color: "#000000",
    }
    try {
      if (chatbotId) {
        await api.put(`/queue/${chatbotId}`, values);
      } else {
        await api.post("/queue", values);
      }

      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveBot = async (values) => {
    console.log("values: ", values);
    try {
      if (chatbotId) {
        await api.put(`/queue/${chatbotId}`, values);
        const { data } = await api.get(`/queue/${chatbotId}`);

        setQueue(data);
        setChatbotId(data.id);
        setIsNamedEdit(null);
        setGreetingMessageEdit(null);
        setShowFileUploadModal(false);
        setFileUploadEdit(null);
      } else {
        const { data } = await api.post("/queue", values);
        setQueue(data);
        setChatbotId(data.id);
        setIsNamedEdit(null);
        setGreetingMessageEdit(null);
        setShowFileUploadModal(false);
        setFileUploadEdit(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${t('chatbots.modal.delete')} ${selectedQueue.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      >
        {t('chatbots.modal.deleteQueueContent')}
      </ConfirmationModal>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={handleClose}
        scroll="paper"
        PaperProps={{
          style: {
            position: 'absolute',
            top: 50
          }
        }}
      >
        <DialogTitle style={{ padding: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
            <WhatsAppIcon style={{ marginRight: '10px', fontSize: '35px' }} />
            <span style={{ fontSize: '24px', fontWeight: 'bolder' }}>
              {queueId
                ? t('chatbots.modal.edit')
                : t('chatbots.modal.add')}
            </span>
          </div>
        </DialogTitle>
        <Formik
          initialValues={queue}
          validateOnChange={true}
          enableReinitialize={true}
          validationSchema={QueueSchema}
          onSubmit={(values, actions) => {
            console.log('here');
            setTimeout(() => {
              handleSaveQueue(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({
            handleChange,
            touched,
            errors,
            isSubmitting,
            values,
          }) => {
            return (<Form>
              <DialogContent dividers style={{ padding: '0px 30px' }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    padding: !isRtl ? '30px 0px 30px 30px' : '30px 30px 30px 0px'
                  }}>
                    <div>
                      <FieldArray name="chatbots">
                        {({ push, remove }) => (
                          <>
                            <Stepper
                              nonLinear
                              activeStep={activeStep}
                              orientation="vertical"
                              className={classes.stepperRoot}
                            >
                              {values.chatbots &&
                                values.chatbots.length > 0 &&
                                values.chatbots.map((info, index) => (
                                  <Step
                                    key={`${index
                                      }-chatbot-step`}
                                    onClick={() => {
                                      setActiveStep(index);
                                      // setGreetingMessageEdit(index);
                                    }}
                                  >
                                    <StepLabel key={`${index}-chatbot`}>
                                      {isNameEdit !== index &&
                                        queue.chatbots[index]?.name ? (
                                        <Typography
                                          className={classes.greetingMessage}
                                          variant="body1"
                                        >
                                          {values.chatbots[index].name}

                                          <IconButton
                                            size="small"
                                            onClick={() => {
                                              setIsNamedEdit(index);
                                              setIsStepContent(false);
                                            }}
                                            className={classes.iconBtn}
                                          >
                                            <EditIcon style={{ fontSize: '18px', color: 'rgb(97, 206, 247)' }} />
                                          </IconButton>

                                          <IconButton
                                            onClick={() => {
                                              setSelectedQueue(info);
                                              setConfirmModalOpen(true);
                                            }}
                                            size="small"
                                          >
                                            <DeleteOutline style={{ fontSize: '20px', color: 'rgb(97, 206, 247)' }} />
                                          </IconButton>
                                        </Typography>
                                      ) : (
                                        <div className={classes.optionContainer}>
                                          <Field
                                            as={TextField}
                                            name={`chatbots[${index}].name`}
                                            variant="outlined"
                                            label={t('chatbots.modal.optionName')}
                                            color="primary"
                                            margin="dense"
                                            disabled={isSubmitting}
                                            inputProps={{ maxLength: 50 }}
                                            error={Object.keys(errors).length !== 0 && errors.chatbots.length && errors.chatbots[index]?.name ? true : false}
                                            helperText={Object.keys(errors).length !== 0 && errors.chatbots.length && errors.chatbots[index]?.name}
                                            required={false}
                                            className={classes.textFieldOption}
                                          />
                                          {/* <FormControlLabel
                                            control={
                                              <Field
                                                as={Switch}
                                                color="primary"
                                                name={`chatbots[${index}].isAgent`}
                                                checked={values.chatbots[index].isAgent || false}
                                              />
                                            }
                                            label={t('chatbots.modal.agent')}
                                          /> */}
                                          <IconButton
                                            size="small"
                                            onClick={() => {
                                              console.log("values in save button: ", values);
                                              handleSaveBot(values);
                                            }}
                                            // onChange={onChangeValue}
                                            disabled={isSubmitting}
                                          >
                                            <SaveIcon style={{ fontSize: '18px', color: 'rgb(97, 206, 247)' }} />
                                          </IconButton>

                                          <IconButton
                                            size="small"
                                            onClick={() => remove(index)}
                                            disabled={isSubmitting}
                                          >
                                            <DeleteOutline style={{ fontSize: '20px', color: 'rgb(97, 206, 247)' }} />
                                          </IconButton>
                                        </div>
                                      )}
                                    </StepLabel>

                                    {isStepContent && queue.chatbots[index] && (
                                      <StepContent>
                                        <>
                                          {isGreetingMessageEdit === index ? (
                                            <div
                                              className={classes.greetingMessage}
                                            >
                                              <div className="flex flex-col w-full justify-end items-end">
                                                <Field
                                                  as={TextField}
                                                  name={`chatbots[${index}].greetingMessage`}
                                                  variant="outlined"
                                                  label={t('chatbots.modal.message')}
                                                  margin="dense"
                                                  fullWidth
                                                  multiline
                                                  minRows={3}
                                                  error={
                                                    touched.greetingMessage &&
                                                    Boolean(errors.greetingMessage)
                                                  }
                                                  helperText={
                                                    touched.greetingMessage &&
                                                    errors.greetingMessage
                                                  }
                                                  className={classes.textField}
                                                  style={{ flex: 1 }}
                                                  inputProps={{ maxLength: 200 }}
                                                />
                                                <p className="text-black text-[12px] pr-2">Max Length: 200</p>
                                              </div>

                                              <IconButton
                                                size="small"
                                                onClick={() => {
                                                  handleSaveBot(values);
                                                }}
                                                disabled={isSubmitting}
                                              >
                                                {" "}
                                                <SaveIcon style={{ fontSize: '18px', color: 'rgb(97, 206, 247)' }} />
                                              </IconButton>
                                            </div>
                                          ) : (
                                            <>
                                              <div
                                                className={classes.greetingMessage}
                                              >
                                                <span className={classes.messageText}>
                                                  {values.chatbots[index]?.greetingMessage}
                                                </span>
                                                <IconButton
                                                  size="small"
                                                  onClick={() =>
                                                    setGreetingMessageEdit(index)
                                                  }
                                                >
                                                  <EditIcon style={{ fontSize: '18px', color: 'rgb(97, 206, 247)' }} />
                                                </IconButton>
                                                <IconButton
                                                  size="small"
                                                  onClick={() => {
                                                    setShowFileUploadModal(true)
                                                    setFileUploadEdit(index)
                                                  }}
                                                >
                                                  <CloudUploadIcon style={{ fontSize: '18px', color: 'rgb(97, 206, 247)' }} />
                                                </IconButton>
                                              </div>
                                              <div style={{ paddingLeft: 10 }}>
                                                {values.chatbots[index]?.file ?
                                                  (values.chatbots[index].file.split(';') || []).map((fileName, idx) => {
                                                    if (idx === values.chatbots[index].file.split(';')) return;
                                                    return (
                                                      <a
                                                        key={idx}
                                                        href={`${process.env.REACT_APP_API_URL}/public/${fileName}`}
                                                        target='_blank'
                                                        className='underline text-[#1d1d1d] text-[12px]'
                                                      >
                                                        <p>{fileName}</p>
                                                      </a>
                                                    )
                                                  }) :
                                                  <span className="text-red-400 text-[12px]">
                                                    No attached files on this option
                                                  </span>
                                                }
                                              </div>
                                            </>
                                          )}

                                          <OptionsChatBot chatBotId={info.id} className={classes.stepperRoot} />
                                        </>
                                      </StepContent>
                                    )}
                                  </Step>
                                ))}

                              <Step>
                                <StepLabel>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                      width: '120px'
                                    }}
                                    onClick={() => {
                                      setAlert(null);
                                      push({ id: values.length, name: "", greetingMessage: "" })
                                    }}
                                  >
                                    <span style={{
                                      color: '#FFA500', paddingRight: 5
                                    }}>{t('chatbots.modal.optionsAdd')}</span>
                                    <AddCircleIcon style={{ fontSize: '20px', color: '#FFA500' }} />
                                  </div>
                                </StepLabel>
                              </Step>
                            </Stepper>
                          </>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                </div>
              </DialogContent>
              {alert &&
                <div className="w-full p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 text-center mt-2" role="alert">
                  <span className="font-medium pr-2">Warning!</span>
                  {alert}
                </div>
              }
              <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.btnWrapper}
                  style={{
                    background: '#61A7DB',
                    color: 'white',
                    padding: '10px 30px'
                  }}
                  onClick={() => {
                    onShowNewModal();
                  }}
                >
                  TREE VIEW
                </Button>
                <div className="flex flex-row justify-end items-center space-x-4">
                  <Button
                    onClick={handleClose}
                    // color="secondary"
                    disabled={isSubmitting}
                  // variant="outlined"

                  >
                    {t('chatbots.modal.buttons.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                    style={{
                      background: '#61A7DB',
                      color: 'white',
                      padding: '10px 30px'
                    }}
                  >
                    {queueId
                      ? t('chatbots.modal.buttons.save')
                      : t('chatbots.modal.buttons.add')}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </div>
              </DialogActions>
            </Form>)
          }}
        </Formik>
      </Dialog>
      <FileModal
        open={showFileUploadModal}
        isFileUploadEdit={isFileUploadEdit}
        onClose={() => {
          setShowFileUploadModal(false)
          setFileUploadEdit(null);
        }}
        setUploadFileName={(index, fileName) => {
          const curQueues = queue;
          if (curQueues.chatbots.length && curQueues.chatbots[index]) {
            curQueues.chatbots[index].file = fileName;
            handleSaveBot(curQueues);
          }
        }}
      />
    </div>
  );
};

export default QueueModal;
