import axios from 'axios'
import config from '../constants/config'

const BASE_URL = config.API_BASE_URL

const api = axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
});

export default api

export function parseError(error, placeholder = "Something went wrong") {
	if (error.isAxiosError) {
		return placeholder
	}

	if (error.message && error.message !== "") {
		return error.message
	}

	const { msg } = error.errors[0]
	return msg
}

export function onSuccess(type, response = undefined) {
	return { type: type, response: response }
}

export function onFailure(type, error) {
	return { type: type, error: error }
}
