import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom"
import LogoSVG from '../../assets/image-logo.png';
import { AiOutlinePieChart, AiOutlineSetting, AiFillSetting, AiFillPieChart, AiOutlineRobot, AiFillRobot,AiFillDollarCircle,AiOutlineUsergroupAdd ,AiTwotoneAlert } from 'react-icons/ai';
import { RiMessage3Line, RiMessage3Fill } from 'react-icons/ri';
import { HiOutlineBookOpen, HiOutlineLogout, HiBookOpen, HiOutlineUser, HiUser } from 'react-icons/hi';
import { MdOutlinePermContactCalendar, MdPermContactCalendar } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import { AuthContext } from '../../core/contexts/AuthProvider';
import useAuth from "../../core/contexts/hooks/useAuth.jsx";


const Sidebar = ({ tab }) => {
  const { handleLogout } = useContext(AuthContext);
  const { user } = useAuth();
  console.log("re",user)
  const navigate = useNavigate();
  const hasFeature = (featureId) => user?.pricePlan?.features_id?.includes(featureId);
  return (
    <div className='w-[63px] h-[100vh] bg-[#2E3087] rounded-tr-[8px]  rounded-br-[8px]'>
      <div className='flex flex-col justify-start items-center space-y-10 px-4'>
        <img src={LogoSVG} className='pt-4 min-w-[50px]' />
        <div className='flex flex-col justify-start items-center space-y-6 text-[#C1C2E7] text-[30px]'>
          <button
            className={`w-10 h-10 flex justify-center items-center rounded-[4px] ${tab === 'dashboard' ? 'bg-[#5B5DAA]' : ''}`}
            onClick={() => navigate('/dashboard')}
            data-tip={'Dashboard'}
          >
            {tab === 'dashboard' ? <AiFillPieChart className='text-white' /> : <AiOutlinePieChart />}
          </button>
          {hasFeature(1) && (
            <button
              className={`w-10 h-10 flex justify-center items-center rounded-[4px] ${tab === 'answer' ? 'bg-[#5B5DAA]' : ''}`}
              onClick={() => navigate('/answer')}
              data-tip={'Question and Answer'}
            >
              {tab === 'answer' ? <HiBookOpen className='text-white' /> : <HiOutlineBookOpen />}
            </button>
          )}
           {hasFeature(2) && (
            <button
              className={`w-10 h-10 flex justify-center items-center rounded-[4px] ${tab === 'inbox' ? 'bg-[#5B5DAA]' : ''}`}
              onClick={() => navigate('/tickets')}
              data-tip={'Inbox'}
            >
              {tab === 'inbox' ? <RiMessage3Fill className='text-white' /> : <RiMessage3Line />}
            </button>
          )}
         {hasFeature(3) && (
            <button
              className={`w-10 h-10 flex justify-center items-center rounded-[4px] ${tab === 'broadcast' ? 'bg-[#5B5DAA]' : ''}`}
              onClick={() => navigate('/broadcast')}
              data-tip={'Broadcast'}
            >
              <AiOutlineUsergroupAdd className={`${tab === 'broadcast' ? 'text-white' : ''}`} />
            </button>
          )}
          
          {user.isMowaselAuth && (
            <button
              className={`w-10 h-10 flex justify-center items-center rounded-[4px] ${tab === 'price' ? 'bg-[#5B5DAA]' : ''}`}
              onClick={() => navigate('/price')}
              data-tip={'Pricing Plan'}
            >
              {tab === 'price' ? <AiFillDollarCircle className='text-white' /> : <AiFillDollarCircle />}
            </button>
          )}
          {user.isSallaAuth && (
            <button
              className={`w-10 h-10 flex justify-center items-center rounded-[4px] ${tab === 'event' ? 'bg-[#5B5DAA]' : ''}`}
              onClick={() => navigate('/event')}
              data-tip={'Event'}
            >
              {tab === 'event' ? <AiTwotoneAlert className='text-white' /> : <AiTwotoneAlert />}
            </button>
          )}
        </div>
        {/* <div className='w-[100%] h-[1px] bg-[#5B5DAA]' /> */}
        <div className='flex flex-col justify-start items-center space-y-8 text-[#C1C2E7] text-[30px]'>
          {/* <button
            className={`w-10 h-10 flex justify-center items-center rounded-[4px] ${tab === 'chatbot' ? 'bg-[#5B5DAA]' : ''}`}
            onClick={() => navigate('/chatbot')}
            data-tip={'ChatBot Management'}
          >
            {tab === 'chatbot' ? <AiFillRobot className='text-white' /> : <AiOutlineRobot />}
          </button> */}
          {/* <button
            className={`w-10 h-10 flex justify-center items-center rounded-[4px] ${tab === 'contact' ? 'bg-[#5B5DAA]' : ''}`}
            onClick={() => navigate('/contact')}
            data-tip={'Contact Management'}
          >
            {tab === 'contact' ? <MdPermContactCalendar className='text-white' /> : <MdOutlinePermContactCalendar />}
          </button> */}
          {/* <button
            className={`w-10 h-10 flex justify-center items-center rounded-[4px] ${tab === 'user' ? 'bg-[#5B5DAA]' : ''}`}
            onClick={() => navigate('/user')}
            data-tip={'User Management'}
          >
            {tab === 'user' ? <HiUser className='text-white' /> : <HiOutlineUser />}
          </button> */}
          {/* <button
            className={`w-10 h-10 flex justify-center items-center rounded-[4px] ${tab === 'connection' ? 'bg-[#5B5DAA]' : ''}`}
            onClick={() => navigate('/connection')}
            data-tip={'Connection Management'}
          >
            {tab === 'connection' ? <AiFillSetting className='text-white' /> : <AiOutlineSetting />}
          </button> */}
          <button
            className={`w-10 h-10 flex justify-center items-center rounded-[4px]`}
            onClick={() => handleLogout()}
            data-tip={'Log out'}
          >
            <HiOutlineLogout />
          </button>
        </div>
      </div>
      <ReactTooltip
        place={'left'}
        isCapture={false}
        effect={'solid'}
        // backgroundColor="#FFA200"
        padding='0px 8px'
        offset={{
          right: '-10px',
          top: '-20px'
        }}
        arrowColor={'transparent'}
        delayShow={150}
      />
    </div>
  )
}

export default Sidebar;