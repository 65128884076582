import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import * as Yup from "yup";
import { Formik, Field, FieldArray } from "formik";
import api from "../../../core/api";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Switch from "@material-ui/core/Switch";
import { FormControlLabel } from "@material-ui/core";
import ConfirmationModal from "../global/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileModal from "./FileModal";
import TreeItem from '@material-ui/lab/TreeItem';
import AddIcon from '@material-ui/icons/Add';
import Typography from "@material-ui/core/Typography";
import { useTreeItemStyles } from ".";

const QueueSchema = Yup.object().shape({
  options: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().min(4, "too short").required("Required"),
      })
    )
    .required("Must have friends"),
});

const useStyles = makeStyles((theme) => ({
  greetingMessage: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    marginRight: theme.spacing(1),
    "& .MuiInputBase-root": {
      background: "#FFF"
    }
  },
  iconBtn: {
    marginRight: '0px !important',
    marginLeft: 15,
  },
  Box: {
    cursor: "pointer",
    alignItems: "center",
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  textFieldOption: {
    width: '200px',
    marginRight: 20
  },
  messageText: {
    color: 'black'
  },
  stepContentContainer: {
    paddingLeft: 10
  },
  addOptionLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    padding: '10px 0px',
  },
  optionLabel: {
    padding: '10px 0px',
    textTransform: 'capitalize'
  }
}));

function getStepContent(
  step, 
  getChildOptionId,
  isStepsLoading,
  getSteps,
  setChatbotsId,
  setIsChildStep, 
  setOptionTitle, 
  setOptionMessage, 
  setOptionFiles,
  handleAddOption
) {
  return <VerticalLinearStepper 
    chatBotId={step} 
    getChildOptionId={getChildOptionId}
    isStepsLoading={isStepsLoading}
    getSteps={getSteps}
    setChatbotsId={setChatbotsId}
    setIsChildStep={setIsChildStep}
    setOptionFiles={setOptionFiles}
    setOptionMessage={setOptionMessage}
    setOptionTitle={setOptionTitle}
    handleAddOption={handleAddOption}
  />;
}

export default function VerticalLinearStepper(props) {
  const initialState = {
    name: "",
    greetingMessage: "",
    options: [],
  };

  const classes = useStyles();
  const treeClasses = useTreeItemStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(-1);
  const [steps, setSteps] = React.useState(initialState);
  const [loading, setLoading] = React.useState(false);
  const [isStepContent, setIsStepContent] = React.useState(true);
  const [isNameEdit, setIsNamedEdit] = React.useState(null);
  const [isGreetingMessageEdit, setGreetingMessageEdit] = React.useState(null);
  const [selectedQueue, setSelectedQueue] = React.useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [isFileUploadEdit, setFileUploadEdit] = React.useState(null);
  const [showFileUploadModal, setShowFileUploadModal] = React.useState(false);

  const handleSaveBot = async (values) => {
    try {
      if (props.chatBotId) {
        await api.put(`/chatbot/${props.chatBotId}`, values);
      } else {
        await api.post("/chatbot", values);
      }
      console.log("Bot saved successfully")
      // setActiveStep(-1)
      const { data } = await api.get(`/chatbot/${props.chatBotId}`);

      setSteps(initialState);
      setSteps(data);
      setIsNamedEdit(null);
      setGreetingMessageEdit(null);
      setShowFileUploadModal(false);
      setFileUploadEdit(null);

      setSteps(data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchList = async () => {
        try {
          const { data } = await api.get(`/chatbot/${props.chatBotId}`);
          setSteps(data);
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      };
      fetchList();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [props.chatBotId, props.isStepsLoading]);

  React.useEffect(() => {
    if (activeStep === isNameEdit) {
      setIsStepContent(false);
    } else {
      setIsStepContent(true);
    }
  }, [isNameEdit, activeStep]);

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/chatbot/${queueId}`);
      const { data } = await api.get(`/chatbot/${props.chatBotId}`);
      setSteps(initialState);
      setSteps(data);
      setIsNamedEdit(null);
      setGreetingMessageEdit(null);
      setSteps(data);
      console.log("Queue deleted successfully!");
    } catch (err) {
      console.log(err)
    }
    setSelectedQueue(null);
  };

  return (
    <>
      {!loading && 
        steps.options &&
        steps.options.length > 0 &&
        steps.options.map((info, index) => {
          return (
            <TreeItem
              key={info.id}
              nodeId={`${info.id}-options`}
              label={
                <Typography className={classes.optionLabel}>
                  {steps.options[index].name}
                </Typography>
              }
              classes={{
                content: treeClasses.content,
                root: treeClasses.root
              }}
              onClick={() => {
                props.getChildOptionId(props.chatBotId);
                props.getSteps(steps);
                props.setChatbotsId(index);
                props.setIsChildStep(true);
                props.setOptionTitle(steps.options[index].name || '');
                props.setOptionMessage(steps.options[index].greetingMessage || '');
                props.setOptionFiles(steps.options[index].file || '');
              }}
            >
              {steps.options[index] && (
                <>
                  {getStepContent(
                    info.id, 
                    props.getChildOptionId,
                    props.isStepsLoading,
                    props.getSteps, 
                    props.setChatbotsId, 
                    props.setIsChildStep, 
                    props.setOptionTitle, 
                    props.setOptionMessage, 
                    props.setOptionFiles,
                    props.handleAddOption,
                  )}
                </>
              )}
              <TreeItem 
                nodeId={`${info.id}-a`}
                label={
                  <Typography className={classes.addOptionLabel}>
                    Add options
                  </Typography>
                }
                classes={{
                  content: treeClasses.addContent,
                  root: treeClasses.addRoot
                }}
                icon={<AddIcon style={{ color: '' }} />}
                onClick={() => {
                  props.handleAddOption(info.id);
                  props.setOptionTitle('');
                  props.setOptionMessage('');
                  props.setOptionFiles('');
                }}
              />
            </TreeItem>
          )
        })}
    </>
  );
}
