import React, { useContext } from 'react';
import Sidebar from './components/Sidebar';
import Headerbar from './components/Headerbar';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { AuthContext } from '../core/contexts/AuthProvider';
import Grid from "@material-ui/core/Grid";
import TicketsManager from './components/ticket/TicketsManager';
import Ticket from './components/ticket/Ticket';
import { Paper } from '@material-ui/core';
import Hidden from "@material-ui/core/Hidden";


const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    padding: '50px 50px',
    height: `calc(90vh - 50px)`,
    maxWidth: '1500px',
    margin: 'auto',
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    maxWidth: '400px'
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    flex: 1,
    height: "100%",
    flexDirection: "column",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  welcomeMsg: {
    // backgroundColor: "#1D1D1D",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  ticketsManager: {},
  ticketsManagerClosed: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Inbox = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { ticketId } = useParams();
  const { user, handleLogout } = useContext(AuthContext);

  return (
    <div className='w-[100vw] h-[100vh] flex bg-[#F7F8FB] overflow-x-hidden'>
      <Sidebar tab='inbox' />
      <div className='flex flex-1 flex-col justify-start items-start max-w-[96vw]'>
        <Headerbar
          title="Inbox"
        />
        <div className='px-10 py-5 flex flex-col justify-start items-start w-[100%] space-y-12'>
          <Grid container spacing={0} className='h-[calc(100vh-110px)]'>
            {/* <Grid item xs={4} className={classes.contactsWrapper}> */}
            <Grid
              item
              xs={12}
              md={4}
              className={
                ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
              }
            >
              <TicketsManager />
            </Grid>
            <Grid container item className={classes.messagessWrapper} elevation={0}>
              {ticketId ? (
                <>
                  <Ticket />
                </>
              ) : (
                <Hidden only={["sm", "xs"]}>
                  <Paper className={classes.welcomeMsg}>
                    {/* <Paper square variant="outlined" className={classes.welcomeMsg}> */}
                    <span>{t('tickets.board.empty')}</span>
                  </Paper>
                </Hidden>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default Inbox;