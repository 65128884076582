import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import USFlag from '../assets/flag/us.png';
import SAFlag from '../assets/flag/sa.png';
import LogoPNG from '../assets/mowasel-logo.png';
import AuthBgPNG from '../assets/auth/auth_bg.png';
import { isValidText, checkPasswordValidation } from '../utils/helper';
import api from '../core/api';
import { Flag } from './components/Headerbar';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../core/contexts/AuthProvider';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MenuItem, Select } from '@material-ui/core';
import { useUserData } from '../core/contexts/UserDataProvider'

const Signup = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [flagType, setFlagType] = useState('en');
  const [industry, setIndustry] = useState('optional');
  const { isRtl, setIsRtl } = useContext(AuthContext);
  const { saveUserData } = useUserData();

  useEffect(() => {
    const flag = localStorage.getItem('flag');
    if (flag) {
      if (flag === 'en') {
        document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
      } else {
        document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
      }
      setFlagType(flag);
      i18n.changeLanguage(flag);
    } else {
      setFlagType('en');
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
      i18n.changeLanguage('en');
    }
  }, [])

  const handleChangeLang = (lang) => {
    setFlagType(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('flag', lang);
    if (lang === 'en') {
      setIsRtl(false);
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    } else {
      setIsRtl(true);
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    }
  }

  const handleSubmit = async (props) => {
    const passwordValidationError = checkPasswordValidation(
      password,
      confirmPassword
    );
    if (passwordValidationError.length > 0) {
      setError(passwordValidationError);
      return;
    }
    try {
      const response = await api.post("/users/checkUserExists", { email: phone });
      console.log(response);
      if (response.data.success) {
        saveUserData({
          name: `${firstName} ${lastName}`,
          email: phone,
          phone: `+${phone}`,
          password,
          industry,
        })
        navigation('/otp');
      }
      else{
        Toast.error(err.response.data.error);
      }
    } catch (err) {
      setError(err.response.data.error);
    }
  }

  return (
    <>
      <div className={`flex flex-row justify-center items-center fixed  space-x-4 ${isRtl ? 'top-10 left-10' : 'top-10 right-10'}`}>
        {flagType === 'sa' ?
          <Flag
            flag={USFlag}
            country={'EN'}
            onClick={() => handleChangeLang('en')}
          /> :
          <Flag
            flag={SAFlag}
            country={'AR'}
            onClick={() => handleChangeLang('sa')}
          />
        }
        <img src={LogoPNG} className='w-[150px]' />
      </div>
      <div className='w-[100vw] h-[100vh] flex flex-row justify-between items-center'>
        <img src={AuthBgPNG} className='h-[100%]' />
        <div className='flex flex-1 h-[100%]'>
          <div className='flex justify-center items-center w-[100%]'>
            <div className='flex flex-col justify-center items-center space-y-4 w-[456px] text-[#1F1F1F]'>
              <div className='text-center w-[100%] !mb-8'>
                <p className='text-[32px] font-[700]'>{t('auth.signup.title')}</p>
                <p className='text-[14px]'>
                  {t('auth.signup.subtitle')}
                </p>
              </div>

              <input
                type='text'
                placeholder={t('auth.signup.placeholder.fullname')}
                className='bg-[#EFEFEF] px-4 focus-visible:outline-none py-3 rounded-[4px] w-[100%]'
                onChange={(event) => {
                  event.target.value = event.target.value.trim();
                  setFirstName(event.target.value.trim());
                }}
                value={firstName || ''}
              />
              {/* <input
                type='text'
                placeholder={t('auth.signup.placeholder.phone')}
                className='bg-[#EFEFEF] px-4 py-3 rounded-[4px] w-[100%]'
                onChange={(event) => setPhone(event.target.value)}
                value={phone || ''}
              /> */}
              <PhoneInput
                country={"sa"}
                preferredCountries={["sa", "us", "gb"]}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                inputClass={"!w-full !bg-[#EFEFEF] !h-[50px] !border-none"}
              />
              <input
                type='password'
                placeholder={t('auth.signup.placeholder.password')}
                className='bg-[#EFEFEF] focus-visible:outline-none px-4 py-3 rounded-[4px] w-[100%]'
                onChange={(event) => {
                  event.target.value = event.target.value.trim();
                  setPassword(event.target.value.trim());
                }}
                value={password || ''}
              />
              <input
                type='password'
                placeholder={t('auth.signup.placeholder.confirmpassword')}
                className='bg-[#EFEFEF] focus-visible:outline-none px-4 py-3 rounded-[4px] w-[100%]'
                onChange={(event) => {
                  event.target.value = event.target.value.trim();
                  setConfirmPassword(event.target.value.trim());
                }}
                value={confirmPassword || ''}
              />
              <Select
                className={'w-[100%]'}
                value={industry}
                onChange={(event) => setIndustry(event.target.value)}
              >
                <MenuItem value={'optional'}>{t('auth.signup.select')}</MenuItem>
                <MenuItem value={'food'}>{t('auth.signup.food')}</MenuItem>
                <MenuItem value={'real'}>{t('auth.signup.real')}</MenuItem>
                <MenuItem value={'auto'}>{t('auth.signup.auto')}</MenuItem>
                <MenuItem value={'health'}>{t('auth.signup.health')}</MenuItem>
                <MenuItem value={'financial'}>{t('auth.signup.financial')}</MenuItem>
                <MenuItem value={'travel'}>{t('auth.signup.travel')}</MenuItem>
                <MenuItem value={'logistic'}>{t('auth.signup.logistic')}</MenuItem>
                <MenuItem value={'hospitality'}>{t('auth.signup.hospitality')}</MenuItem>
                <MenuItem value={'other'}>{t('auth.signup.other')}</MenuItem>
              </Select>
              <p className='text-[12px] text-[#7C7C7C] w-[100%] text-left'>
                {t('auth.signup.terms')}
              </p>
              <button
                className={`bg-[#2E3087] rounded-[4px] w-[100%] py-3 text-white text-center ${!isValidText(phone) || !isValidText(password) ? 'cursor-not-allowed' : ''}`}
                onClick={() => handleSubmit()}
                disabled={!isValidText(phone) || !isValidText(password)}
              >
                {t('auth.signup.signup')}
              </button>
              {error ?
                <div className="w-full p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
                  <span className="font-medium pr-2">Warning!</span>
                  {error}
                </div> : null
              }
              <p className='text-[14px] text-[#7C7C7C] w-[100%] text-center !mt-8'>
                {t('auth.signup.already')} <a onClick={() => navigation('/login')} className="underline text-[#2E3087] font-bold cursor-pointer">
                  {t('auth.signin.signin')}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Signup;