import { useState, useEffect } from "react";
import api from "../../api";

const useTickets = ({
  searchParam,
  pageNumber,
  status,
  date,
  showAll,
  queueIds,
  withUnreadMessages,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState(0);

  // useEffect(() => {
  //   setLoading(true);
  //   const delayDebounceFn = setTimeout(() => {
  //     const fetchTickets = async () => {
  //       try {
  //         const { data } = await api.get("/tickets", {
  //           params: {
  //             searchParam,
  //             pageNumber,
  //             status,
  //             date,
  //             showAll,
  //             queueIds,
  //             withUnreadMessages,
  //           },
  //         })
  //         setTickets(data.tickets)
  //         setHasMore(data.hasMore)
  //         setCount(data.count)
  //         setLoading(false)
  //       } catch (err) {
  //         setLoading(false)
  //         console.log("err: ", err);
  //       }
  //     }

  //     const closeTicket = async (ticket) => {
  //       await api.put(`/tickets/${ticket.id}`, {
  //         status: "closed",
  //         userId: ticket.userId || null,
  //       })
  //     }
  //     fetchTickets()
  //   }, 500)
  //   return () => clearTimeout(delayDebounceFn)
  // }, [
  //   searchParam,
  //   pageNumber,
  //   status,
  //   date,
  //   showAll,
  //   queueIds,
  //   withUnreadMessages,
  // ])

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContactList = async () => {
        try {
          const { data } = await api.get("/contacts-list")
          setTickets(data.contactList)
          setHasMore(false)
          setCount(data.count)
          setLoading(false)
        } catch (err) {
          setLoading(false)
          console.log("err during get all contact list: ", err);
        }
      }
      fetchContactList()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [])

  return { tickets, loading, hasMore, count };
};

export default useTickets;