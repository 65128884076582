import React, { useState, useEffect, useContext, useReducer } from 'react';
import Sidebar from './components/Sidebar';
import Headerbar from './components/Headerbar';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SendIcon from '@material-ui/icons/Send';
import EditIcon from "@material-ui/icons/Edit";
import TableRowSkeleton from './components/material/TableRowSkeleton';
import AnswerModal from './components/answer/AnswerModal';

import { AuthContext } from '../core/contexts/AuthProvider';
import api from '../core/api';
import openSocket from '../core/socket-io';
import ConfirmationModal from './components/global/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import BroadcastModal from './components/broadcast/BroadcastModal';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    width: '100%',
    ...theme.scrollbarStyles,
    border: 0
  },
  tableRoot: {
    "& .MuiTableCell-head": {
      color: "#2E3087",
      backgroundColor: "#E8F3FA",
      fontWeight: 700,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:first-child": {
        borderLeft: 0,
        borderTopLeftRadius: 8
      },
      "&:last-child": {
        borderTopRightRadius: 8
      }
    },
    "& .MuiTableCell-body": {
      color: "#263238",
      fontWeight: 400,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:last-child": {
        borderRight: '1px solid #E0ECF2'
      }
    },
  }
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_BROADCASTS") {
    const answers = action.payload;
    const newAnswers = [];
    answers.forEach((answer) => {
      const answerIndex = state.findIndex((a) => a.id === answer.id);
      if (answerIndex !== -1) {
        state[answerIndex] = answer;
      } else {
        newAnswers.push(answer);
      }
    });

    return [...state, ...newAnswers];
  }

  if (action.type === "UPDATE_BROADCASTS") {
    const answer = action.payload;
    const answerIndex = state.findIndex((a) => a.id === answer.id);

    if (answerIndex !== -1) {
      state[answerIndex] = answer;
      return [...state];
    } else {
      return [answer, ...state];
    }
  }

  if (action.type === "DELETE_BROADCAST") {
    const answerId = action.payload;

    const answerIndex = state.findIndex((a) => a.id === answerId);
    if (answerIndex !== -1) {
      state.splice(answerIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Broadcast = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalOpenForSend, setConfirmModalOpenForSend] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [answerModalOpen, setAnswerModalOpen] = useState(false);
  const { handleLogout } = useContext(AuthContext);
  const [answers, dispatch] = useReducer(reducer, []);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [deletingAnswer, setDeletingAnswer] = useState(null);
  const [sendingBroadcast, setSendingBroadcast] = useState(null);


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchBroadcasts = async () => {
        try {
          const { data } = await api.get("/broadcast/", {
            params: { searchParam, pageNumber },
          });
          console.log("all broadcast: ", data);
          dispatch({ type: "LOAD_BROADCASTS", payload: data });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          // toastError(err);
          console.log("err during get broadcasts: ", err);
        }
      };
      fetchBroadcasts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  const refresh = async () => {
    try {
      const { data } = await api.get("/broadcast/", {
        params: { searchParam, pageNumber },
      });
      console.log("all broadcast: ", data);
      dispatch({ type: "LOAD_BROADCASTS", payload: data });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      // toastError(err);
      console.log("err during get broadcasts: ", err);
    }
  }

  useEffect(() => {
    const socket = openSocket();

    socket.on("broadcast", (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_BROADCASTS", payload: data.broadcast });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_BROADCAST", payload: +data.broadcastId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleDeleteAnswer = async (broadcastId) => {
    try {
      await api.delete(`/broadcast/${broadcastId}`);
      console.log("broadcast deleted");
    } catch (err) {
      // toastError(err);
      console.log("err during delete broadcast: ", err);
    }
    setDeletingAnswer(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleBroadcast = async (broadcast) => {
    if (!broadcast.content.includes('{name}')) {
      toast("Please include {name} in the content.", { type: toast.TYPE.WARNING });
      return;
    }
    try {
      await api.post(`/broadcast/${broadcast.id}`);
    } catch (err) {
      console.log("err during send messages: ", err);
    }
    setSendingBroadcast(null);
    setSearchParam("");
    setPageNumber(1);
  }


  const handleEditAnswer = (answer) => {
    setSelectedAnswer(answer);
    setAnswerModalOpen(true);
  }

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleOpenAnswerModal = () => {
    setSelectedAnswer(null);
    setAnswerModalOpen(true);
  }

  const handleCloseAnswerModal = () => {
    setAnswerModalOpen(false);
  }

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  return (
    <div className='w-[100vw] h-[100vh] flex bg-[#F7F8FB] overflow-x-hidden'>
      <Sidebar tab='broadcast' />
      <div className='flex flex-1 flex-col justify-start items-start'>
        <Headerbar
          title={t('broadcast.title')}
        />
        <div className='px-10 py-5 flex flex-col justify-start items-start w-[100%] space-y-12'>
          <div className='flex flex-col justify-start items-start w-[100%] text-[#263238] font-[500] space-y-4'>
            <p className='text-[24px] leading-[32px]'>
              {t('broadcast.subtitle')}
            </p>
            <div className='flex flex-row justify-between items-end w-[100%]'>
              <div className='max-w-[630px] text-[16px]'>
                <span>
                  {t('broadcast.subtext')}
                </span>
              </div>
              <div className='flex flex-row justify-end items-center space-x-4 text-[14px]'>
                <input
                  type='text'
                  placeholder='Search...'
                  className='px-4 py-2 bg-transparent border-[1px] border-[#DADBDD] rounded-[4px]'
                />
                <button
                  className='bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]'
                  onClick={refresh}
                >
                  {t('broadcast.buttons.refresh')}
                </button>
                <button
                  className='bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]'
                  onClick={handleOpenAnswerModal}
                >
                  {t('broadcast.buttons.add')}
                </button>
              </div>
            </div>
          </div>
          <ConfirmationModal
            title={
              deletingAnswer &&
              `${t('broadcast.modal.delete')}?`
            }
            open={confirmModalOpen}
            onClose={setConfirmModalOpen}
            onConfirm={() => handleDeleteAnswer(deletingAnswer.id)}
          >
            {t('broadcast.modal.deleteContent')}
          </ConfirmationModal>
          <ConfirmationModal
            title={
              sendingBroadcast &&
              `${t('broadcast.modal.send')}?`
            }
            open={confirmModalOpenForSend}
            onClose={setConfirmModalOpenForSend}
            onConfirm={() => handleBroadcast(sendingBroadcast)}
          >
            {t('broadcast.modal.sendContent')}
          </ConfirmationModal>
          <BroadcastModal
            open={answerModalOpen}
            onClose={handleCloseAnswerModal}
            aria-labelledby="form-dialog-title"
            answerId={selectedAnswer && selectedAnswer.id}
          />
          <Paper
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table aria-label="user table" className={classes.tableRoot}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('broadcast.table.no')}</TableCell>
                  <TableCell align="left">{t('broadcast.table.name')}</TableCell>
                  <TableCell align="left">{t('broadcast.table.message')}</TableCell>
                  <TableCell align="left">{t('broadcast.table.type')}</TableCell>
                  <TableCell align="left">{t('broadcast.table.link')}</TableCell>
                  <TableCell align="left">{t('broadcast.table.status')}</TableCell>
                  <TableCell align="right">{t('broadcast.table.actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {answers.map((answer, index) => {
                    let file = answer.file;
                    let fileNameArr = file?.split(";") || [];
                    return <TableRow key={answer.id}>
                      <TableCell align="left" className='w-[10%]'>
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">
                        {answer.name}
                      </TableCell>
                      <TableCell align="left">
                        {answer.content}
                      </TableCell>
                      <TableCell align="left">
                        {answer.type == "0" ?
                          "All" : "Custom"
                        }
                      </TableCell>
                      <TableCell align="left" className='w-[20%]'>
                        {fileNameArr.length > 1 ?
                          fileNameArr.map((fileName, idx) => {
                            return (
                              <a
                                key={idx}
                                href={`${process.env.REACT_APP_API_URL}/public/${fileName}`}
                                target='_blank'
                                className='underline' rel="noreferrer"
                              >
                                <p>{fileName}</p>
                              </a>
                            )
                          }) :
                          "No attached files"
                        }
                      </TableCell>
                      <TableCell align="left" className='w-[10%]'>
                        {answer.status || '0'} of {answer.total || '0'}
                      </TableCell>
                      <TableCell align="right" className='w-[10%]'>
                        {/* <IconButton
                          size="small"
                          onClick={() => handleEditAnswer(answer)}
                        >
                          <EditIcon />
                        </IconButton> */}

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            if (answer.sent) {
                              toast("This broadcast is already sent.", { type: toast.TYPE.WARNING });
                              return;
                            }
                            setConfirmModalOpenForSend(true);
                            setSendingBroadcast(answer);
                          }}
                        >
                          <SendIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingAnswer(answer);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  })}
                  {loading ?
                    <TableRowSkeleton columns={7} /> :
                    answers.length === 0 ?
                      <TableRow>
                        <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                          There is no data.
                        </TableCell>
                      </TableRow>
                      : null
                  }
                </>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    </div>
  )
}

export default Broadcast;