import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Can } from "../global/Can";
import CustomButton from "../global/CustomButton";

import QueueSelect from "../global/QueueSelect";
import { useTranslation } from 'react-i18next';
import useWhatsApps from "../../../core/contexts/hooks/useWhatsApps";
import api from "../../../core/api";
import { AuthContext } from "../../../core/contexts/AuthProvider";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "flex-start",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  actions: {
    padding: '20px 20px'
  },
  formControl: {
    minWidth: 120,
  },
}));

const UserModal = ({ open, onClose, userId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isRtl } = useContext(AuthContext);

  const initialState = {
    name: "",
    email: "",
    password: "",
    profile: "user"
  };

  // const { user: loggedInUser } = useContext(AuthContext);
  const loggedInUser = {
    profile: 'admin'
  };

  const [user, setUser] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [whatsappId, setWhatsappId] = useState(false);
  const { loading, whatsApps } = useWhatsApps();

  const UserSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('yup.short'))
      .max(50, t('yup.long'))
      .required(t('yup.required')),
    password: Yup.string().min(5, t('yup.short')).max(50, t('yup.long')),
    email: Yup.string().email(t('yup.invalidEmail')).required(t('yup.required')),
  });

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const { data } = await api.get(`/users/${userId}`);
        setUser(prevState => {
          return { ...prevState, ...data };
        });
        const userQueueIds = data.queues?.map(queue => queue.id);
        setSelectedQueueIds(userQueueIds);
        setWhatsappId(data.whatsappId ? data.whatsappId : '');
      } catch (err) {
        // toastError(err);
        console.log("err during get user: ", err);
      }
    };

    fetchUser();
  }, [userId, open]);

  useEffect(() => {
    const fetchUser = async () => { };
    fetchUser();
  }, []);

  const handleClose = () => {
    onClose();
    setUser(initialState);
  };

  const handleSaveUser = async values => {
    const userData = { ...values, whatsappId, queueIds: selectedQueueIds };
    try {
      console.log("userId: ", userId);
      if (userId) {
        await api.put(`/users/${userId}`, userData);
      } else {
        await api.post("/users", userData);
      }
      console.log("success to add user");
    } catch (err) {
      // toastError(err);
      console.log("err during add the user: ", err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {userId
            ? t('users.modal.edit')
            : t('users.modal.add')}
        </DialogTitle>
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveUser(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <p className="text-[16px] leading-[21px] text-[#263238]">{t('contacts.modal.contactDetail')}</p>
                <div className={`flex flex-row justify-between items-start ${isRtl ? 'space-x-reverse space-x-3' : 'space-x-3'}`}>
                  <Field
                    as={TextField}
                    label={t('users.modal.name')}
                    autoFocus
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    as={TextField}
                    name="password"
                    variant="outlined"
                    margin="dense"
                    label={t('users.modal.password')}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((e) => !e)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    fullWidth
                  />
                </div>
                <div className="flex flex-row justify-between items-start space-x-3">
                  <Field
                    as={TextField}
                    label={t('users.modal.email')}
                    name="email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                  {/* <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    margin="dense"
                  >
                    <Can
                      role={loggedInUser.profile}
                      perform="user-modal:editProfile"
                      yes={() => (
                        <>
                          <InputLabel id="profile-selection-input-label">
                            {t('users.modal.profile')}
                          </InputLabel>

                          <Field
                            as={Select}
                            label={t('users.modal.profile')}
                            name="profile"
                            labelId="profile-selection-label"
                            id="profile-selection"
                            required
                          >
                            <MenuItem value="admin">{t('users.modal.admin')}</MenuItem>
                            <MenuItem value="user">{t('users.modal.user')}</MenuItem>
                          </Field>
                        </>
                      )}
                    />
                  </FormControl> */}
                </div>
                {/* <Can
                  role={loggedInUser.profile}
                  perform="user-modal:editQueues"
                  yes={() => (
                    <QueueSelect
                      selectedQueueIds={selectedQueueIds}
                      onChange={values => setSelectedQueueIds(values)}
                    />
                  )}
                />
                <Can
                  role={loggedInUser.profile}
                  perform="user-modal:editQueues"
                  yes={() => (!loading &&
                    <FormControl variant="outlined" margin="dense" className={classes.maxWidth} style={{ marginTop: 20 }} fullWidth>
                      <InputLabel>{t('users.modal.whatsapp')}</InputLabel>
                      <Field
                        as={Select}
                        value={whatsappId}
                        onChange={(e) => setWhatsappId(e.target.value)}
                        label={t('users.modal.whatsapp')}
                      >
                        <MenuItem value={''}>&nbsp;</MenuItem>
                        {whatsApps.map((whatsapp) => (
                          <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  )}
                /> */}
              </DialogContent>
              <DialogActions className={classes.actions}>
                <CustomButton
                  text={t('users.modal.buttons.cancel')}
                  onClick={handleClose}
                  type={'cancel'}
                  isSubmitting={isSubmitting}
                />
                <CustomButton
                  text={
                    userId
                      ? t('users.modal.buttons.edit')
                      : t('users.modal.buttons.add')
                  }
                  type={'confirm'}
                  isSubmitting={isSubmitting}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default UserModal;
