import React, { useContext, useState, useEffect } from 'react';
import DefaultUserSVG from '../../assets/layout/default_user.svg';
import { GrNotification } from 'react-icons/gr';
import USFlag from '../../assets/flag/us.png';
import SAFlag from '../../assets/flag/sa.png';
import { AuthContext } from '../../core/contexts/AuthProvider';
import UserModal from './user/UserModal';
import {
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';

export const Flag = ({ flag, country, onClick }) => {
  return (
    <div className='flex flex-row justify-center items-center space-x-2 rounded-[4px] bg-[#E8F3FA] px-4 py-2 cursor-pointer select-none' onClick={onClick}>
      <img src={flag} className='rounded-full w-[20px] h-[20px]' />
      <span>{country}</span>
    </div>
  )
}

const Headerbar = ({ title }) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [flagType, setFlagType] = useState('en');
  const { user, handleLogout, loading, setIsRtl } = useContext(AuthContext);

  useEffect(() => {
    const flag = localStorage.getItem('flag');
    if (flag) {
      if (flag === 'en') {
        document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
      } else {
        document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
      }
      setFlagType(flag);
      i18n.changeLanguage(flag);
    } else {
      setFlagType('en');
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
      i18n.changeLanguage('en');
    }
  }, [])

  const handleChangeLang = (lang) => {
    setFlagType(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('flag', lang);
    if (lang === 'en') {
      setIsRtl(false);
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    } else {
      setIsRtl(true);
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    }
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  return (
    <>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <div className='w-[100%] h-[64px] flex flex-row justify-between items-center px-6 py-3 bg-white border-b-[1px] border-[#E9EFF3]'>
        <span className='text-[24px] font-[700] text-[#263238]'>
          {title}
        </span>
        <div className='flex flex-row justify-end items-center space-x-4'>
          {/* <input
            type='text'
            placeholder='Search...'
            className='px-4 py-2 bg-[#EFF1F4] rounded-[4px]'
          /> */}
          {/* <div className='w-8 h-8 rounded-full border-[#D9E2EF] border-[1px] flex justify-center items-center'>
            <GrNotification className='text-[#374957] text-[16px]' />
          </div> */}
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <img src={DefaultUserSVG} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                Profile
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                Logout
              </MenuItem>
            </Menu>
          </div>
          {flagType === 'sa' ?
            <Flag
              flag={USFlag}
              country={'EN'}
              onClick={() => handleChangeLang('en')}
            /> :
            <Flag
              flag={SAFlag}
              country={'AR'}
              onClick={() => handleChangeLang('sa')}
            />
          }
        </div>
      </div>
    </>
  )
}

export default Headerbar;