import React, { useState, useEffect, useContext, useReducer, useRef } from 'react';
import Sidebar from './components/Sidebar.js';
import Headerbar from './components/Headerbar.js';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import TableRowSkeleton from './components/material/TableRowSkeleton.js';
import AnswerModal from './components/answer/AnswerModal.js';
import useAuth from "../core/contexts/hooks/useAuth.jsx";

import api from '../core/api.jsx';
import openSocket from '../core/socket-io.jsx';
import ConfirmationModal from './components/global/ConfirmationModal.js';
import { useTranslation } from 'react-i18next';
import XLSX from 'sheetjs-style';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    border: "1px solid"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  mainPaper: {
    flex: 1,
    width: '100%',
    ...theme.scrollbarStyles,
    border: 0
  },
  tableRoot: {
    "& .MuiTableCell-head": {
      color: "#2E3087",
      backgroundColor: "#E8F3FA",
      fontWeight: 700,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:first-child": {
        borderLeft: 0,
        borderTopLeftRadius: 8
      },
      "&:last-child": {
        borderTopRightRadius: 8
      }
    },
    "& .MuiTableCell-body": {
      color: "#263238",
      fontWeight: 400,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:last-child": {
        borderRight: '1px solid #E0ECF2'
      }
    },
  },

}));

const Event = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useAuth();
  const [events, setEvents] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  const [activeEventIds, setActiveEventIds] = useState([]);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const { data } = await api.get("/event_types/", {
          params: { searchParam, pageNumber },
        });
        console.log("userd: ", user.eventIds);
        // dispatch({ type: "LOAD_ANSWERS", payload: data });.
        setEventTypes(data.event_types)
        setHasMore(data.hasMore);
        setLoading(false);
      } catch (err) {
        // toastError(err);
        console.log("err during get answers: ", err);
      }
    };
    fetchAnswers();
    getEvents();
  }, [])

  useEffect(() => {
    if (user && user.eventIds) {
      setActiveEventIds(user.eventIds);
    }
  }, [user]);


  const getEvents = async () => {
    const token = localStorage.getItem('token');
    api.defaults.headers.Authorization = `Bearer ${token}`;
    const { data } = await api.get('/events', {
      params: { searchParam: '', pageNumber: 1 },
    });
    // Format data right after fetching
    setEvents(data.events);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    getEvents();
  };

  // const handleToggleActive = async (eventId, isActive) => {
  //   try {
  //     // Assuming your API has an endpoint for toggling the active state
  //     await api.put(`users/${user.id}/update_events/${eventId}`, {
  //       is_active: !isActive,
  //     });
  //     // Now, reload the events data to reflect the change
  //     getEvents(); // You might need to adjust this call depending on your state management
  //   } catch (err) {
  //     console.error("Error toggling active state:", err);
  //     // Optionally, show an error message to the user
  //   }
  // };

  const handleToggleActive = async (eventId) => {
    // Check if the event is currently marked as active
    const isCurrentlyActive = activeEventIds.includes(eventId);

    // Update the local activeEventIds state based on the event's current status
    try {
      // Assuming your API has an endpoint for toggling the active state
      await api.put(`users/${user.id}/update_events/${eventId}`, {
        is_active: !isCurrentlyActive,
      });
      // Now, reload the events data to reflect the change
      getEvents(); // You might need to adjust this call depending on your state management
    } catch (err) {
      console.error("Error toggling active state:", err);
      // Optionally, show an error message to the user
    }

    const updatedActiveEventIds = isCurrentlyActive
      ? activeEventIds.filter(id => id !== eventId) // Remove the id, making it inactive
      : [...activeEventIds, eventId]; // Add the id, making it active

    setActiveEventIds(updatedActiveEventIds);

    // Here, also update your global state or backend to reflect this change
    // For example, using a context method or API call
  };

  return (
    <div className='w-[100vw] h-[100vh] flex bg-[#F7F8FB]'>
      <Sidebar tab='event' />
      <div className='flex flex-1 flex-col justify-start items-start'>
        <Headerbar
          title={t('event.title')}
        />
        <div className='px-10 py-5 flex flex-col justify-start items-start w-[100%] space-y-12 overflow-auto'>
          <div className='flex justify-between items-center w-[100%] mb-4 '>
            {/* Placeholder for any other content or spacing you might want on the left */}
            <div></div>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Types</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedValue}
                onChange={handleChange}
                label="Select Type"
              >
                <MenuItem value={0}>All</MenuItem>
                {eventTypes.map((item) => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Paper
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table aria-label="user table" className={classes.tableRoot}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t('event.table.no')}</TableCell>
                  <TableCell align="center">{t('event.table.name')}</TableCell>
                  <TableCell align="center">{t('event.table.description')}</TableCell>
                  <TableCell align="center">{t('event.table.type')}</TableCell>
                  <TableCell align="center">{t('event.table.status')}</TableCell>
                  <TableCell align="center">{t('event.table.actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {events.filter((event) => selectedValue === 0 || event.type_id === selectedValue).map((event, index) => {
                    const isEventAvailable = activeEventIds.includes(event.id);
                    return <TableRow key={event.id} style={{ color: 'gray' }}>
                      <TableCell align="center" className='w-[10%]'>{index + 1}</TableCell>
                      <TableCell align="center">{event.name}</TableCell>
                      <TableCell align="center">{event.description}</TableCell>
                      <TableCell align="center">{event.eventTypeName}</TableCell>
                      {isEventAvailable ? <TableCell align="center">Available</TableCell> : <TableCell align="center" style={{ color: 'gray' }}>Not Available</TableCell>}
                      <TableCell align="center" className='w-[10%]'>
                        <IconButton
                          size="small"
                          // onClick={() => handleEditAnswer(answer)}
                          onClick={() => handleToggleActive(event.id)}
                          className={classes.iconButton}
                        >
                          {isEventAvailable ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  })}
                  {loading ?
                    <TableRowSkeleton columns={5} /> :
                    events.length === 0 ?
                      <TableRow>
                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                          There is no data.
                        </TableCell>
                      </TableRow>
                      : null
                  }
                </>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    </div >
  )
}

export default Event;