import React, { createContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import useAuth from "./hooks/useAuth.jsx";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const [isRtl, setIsRtl] = useState(false);
	const { loading, user, isAuth, err, setErr, handleLogin, handleLogout } = useAuth();
	useEffect(() => {
		const flag = localStorage.getItem('flag');
		if (flag) {
			if (flag === 'sa') {
				setIsRtl(true);
			}
		}
	}, [])
	return (
		<AuthContext.Provider
			value={{ loading, user, isAuth, err, setErr, handleLogin, handleLogout, setIsRtl, isRtl }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
