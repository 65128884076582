import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import api from "../../../core/api";
import openSocket from '../../../core/socket-io';
import { useTranslation } from 'react-i18next';

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
	const { t } = useTranslation();
	const [qrCode, setQrCode] = useState("");

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`/whatsapp/${whatsAppId}`);
				setQrCode(data.qrcode);
			} catch (err) {
				console.log("err: ", err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	useEffect(() => {
		if (!whatsAppId) return;
		const socket = openSocket();

		socket.on("whatsappSession", data => {
			if (data.action === "update" && data.session.id === whatsAppId) {
				setQrCode(data.session.qrcode);
			}

			if (data.action === "update" && data.session.qrcode === "") {
				onClose();
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [whatsAppId, onClose]);

	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
			<DialogContent style={{ padding: 30 }}>
				<Paper elevation={0}>
					<Typography gutterBottom align="center" className="text-[#61A7DB]">
						{t('connections.modal.scan')}
					</Typography>
					{qrCode ? (
						<div style={{ width: '100%', height: '100%', background: 'white', padding: 15, borderRadius: 5 }}>
							<QRCode value={qrCode} size={256} />
						</div>
					) : (
						<span>{t('connections.modal.scanWaiting')}</span>
					)}
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModal);
