// Priceplan.js
import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import Headerbar from './components/Headerbar';
import { makeStyles } from "@material-ui/core/styles";
import PricePlanCard from './components/price/PricePlanCard';
import api from '../core/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useAuth from "../core/contexts/hooks/useAuth.jsx";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    width: '100%',
    ...theme.scrollbarStyles,
    border: 0
  },
  tableRoot: {
    "& .MuiTableCell-head": {
      color: "#2E3087",
      backgroundColor: "#E8F3FA",
      fontWeight: 700,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:first-child": {
        borderLeft: 0,
        borderTopLeftRadius: 8
      },
      "&:last-child": {
        borderTopRightRadius: 8
      }
    },
    "& .MuiTableCell-body": {
      color: "#263238",
      fontWeight: 400,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:last-child": {
        borderRight: '1px solid #E0ECF2'
      }
    },
  }
}));

const Priceplan = () => {

  const [pricePlans, setPricePlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const { user } = useAuth();

  useEffect(() => {
    const fetchPricePlans = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.get('/price_plan', {
          params: { searchParam: '', pageNumber: 1 },
        });
        setPricePlans(data.plans);
      } catch (err) {
        setError(err.message);
        toast.error(t('errors.fetch'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchPricePlans();
    console.log("price", user)
  }, [t]);

  const handleEdit = (plan) => {
    console.log("Edit plan:", plan);
    // Implementation for edit functionality
  };

  const handleDelete = async (planId) => {
    // Implementation for delete functionality
    try {
      setIsLoading(true);
      await api.post('/price_plan/delete_subscribe', { planId: planId, userId: user.id });
    } catch (err) {
      setError(err.message);
      toast.error(t('errors.fetch'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = async (planId) => {
    try {
      setIsLoading(true);
      const { data } = await api.post('/price_plan/subscribe', { planId: planId, userId: user.id });
      window.open(data, '_blank');
    } catch (err) {
      setError(err.message);
      toast.error(t('errors.fetch'));
    } finally {
      setIsLoading(false);
    }
    // Implementation for subscribe functionality
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="text-white text-lg">Please wait while confirming user Information...</div>
        </div>
      )}
      <div className='w-[100vw] h-[100vh] flex bg-[#F7F8FB] overflow-x-hidden justify-center'>
        <Sidebar tab='price' />
        <div className='flex flex-1 flex-col justify-start items-center'>
          <Headerbar
            title={t('price.title')}
          />
          <div className="px-4 py-2 w-full overflow-auto">
            <div className="max-w-screen-lg mx-auto">
              <h2 className="text-2xl font-bold text-center mb-6">{t('price.choose')}</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {pricePlans.length > 0 ? (
                  pricePlans.map(plan => (
                    <PricePlanCard key={plan.id} plan={plan} onSubscribe={handleSubscribe} deleteScribe={handleDelete} isSubscribed={user.pricePlanId === plan.id} />
                  ))
                ) : (
                  <p>Loading...</p> // Or any other loading indicator
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Priceplan;
