import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import USFlag from '../assets/flag/us.png';
import SAFlag from '../assets/flag/sa.png';
import LogoPNG from '../assets/mowasel-logo.png';
import AuthBgPNG from '../assets/auth/auth_bg.png';
import api from '../core/api';
import { Flag } from './components/Headerbar';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../core/contexts/AuthProvider';
import { useUserData } from '../core/contexts/UserDataProvider';
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";

const SignupOtp = () => {
    const { t, i18n } = useTranslation();
    const navigation = useNavigate();
    const [resendOtp, setResendOtp] = useState(false);
    const [otpID, setOtpID] = useState();
    const [otpInputValue, setInputValue] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [flagType, setFlagType] = useState('en');
    const { isRtl, setIsRtl } = useContext(AuthContext);
    const { userData } = useUserData();

    const handleChangeLang = (lang) => {
        setFlagType(lang);
        i18n.changeLanguage(lang);
        localStorage.setItem('flag', lang);
        if (lang === 'en') {
            setIsRtl(false);
            document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
        } else {
            setIsRtl(true);
            document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
        }
    }
    useEffect(() => {
        const flag = localStorage.getItem('flag');
        if (flag) {
            if (flag === 'en') {
                document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
            } else {
                document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
            }
            setFlagType(flag);
            i18n.changeLanguage(flag);
        } else {
            setFlagType('en');
            document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
            i18n.changeLanguage('en');
        }
        if (!isOtpSent) {
            sendOtp();
            setIsOtpSent(true);
        }
    }, [])

    const sendOtp = async () => {
        try {
            const response = await api.post("/send-otp", { phoneNumber: userData?.phone });
            if (response?.data?.code === 1) {
                setOtpID(response?.data?.id);
                toast.success('Otp Sent Successfully')
            }
            else {
                toast.error(response?.data?.message)
            }
        } catch (err) {
            toast.error(err?.response?.data?.message);
        }
    }

    const handleSubmit = async () => {
        if (userData) {
            try {
                const response = await api.post("/verify-otp", { otpID, code: otpInputValue });
                if (response?.data?.code === 1) {
                    registerUser();
                }
                else if (response?.data?.code === 400) {
                    toast.error(`${response?.data?.message}, Resend OTP Again`)
                    setResendOtp(true)
                }
                else {
                    toast.error(response?.data?.message)
                }
            } catch (err) {
                toast.error(err?.response?.data?.message);
            }
        }
    };

    const registerUser = async () => {
        if (userData) {
            try {
                const response = await api.post("/auth/signup", userData);
                if (response.status === 200) {
                    toast.success('Otp Verified, User Registered Successfully')
                    navigation("/login");
                }
            } catch (err) {
                toast.error(err?.response?.data?.error)
            }
        }
    }

    const otpResendFunc = async () => {
        sendOtp();
        setResendOtp(false)
    }

    return (
        <>
            <div className={`flex flex-row justify-center items-center fixed  space-x-4 ${isRtl ? 'top-10 left-10' : 'top-10 right-10'}`}>
                {flagType === 'sa' ?
                    <Flag
                        flag={USFlag}
                        country={'EN'}
                        onClick={() => handleChangeLang('en')}
                    /> :
                    <Flag
                        flag={SAFlag}
                        country={'AR'}
                        onClick={() => handleChangeLang('sa')}
                    />
                }
                <img src={LogoPNG} className='w-[150px]' />
            </div>
            <div className='w-[100vw] h-[100vh] flex flex-row justify-between items-center'>
                <img src={AuthBgPNG} className='h-[100%]' />
                <div className='flex flex-1 h-[100%]'>
                    <div className='flex justify-center items-center w-[100%]'>
                        <div className='flex flex-col justify-center items-center space-y-3 w-[456px] text-[#1F1F1F]'>
                            <div className='text-center w-[100%] !mb-8'>
                                <p className='text-[32px] font-[700]'>{t('auth.signup.otp.title')}</p>
                                <p className='text-[14px]'>
                                    {t('auth.signup.otp.subtitle')}
                                </p>
                            </div>

                            {resendOtp ? (
                                <button
                                    className={`bg-[#2E3087] rounded-[4px] w-[100%] py-3 text-white text-center cursor-pointer`}
                                    onClick={() => otpResendFunc()}
                                >
                                    {t('auth.signup.otp.resend')}
                                </button>
                            ) : (
                                <>
                                    <input
                                        type='text'
                                        placeholder={t('auth.signup.otp.placeholder.code')}
                                        className='bg-[#EFEFEF] focus-visible:outline-none px-4 py-3 rounded-[4px] w-[100%]'
                                        onChange={(event) => {
                                            event.target.value = event.target.value.trim();
                                            setInputValue(event.target.value.trim());
                                        }}
                                        value={otpInputValue || ''}
                                    />
                                    <button
                                        className={`bg-[#2E3087] rounded-[4px] w-[100%] py-3 text-white text-center ${!otpInputValue ? 'hover:cursor-not-allowed' : ''}`}
                                        onClick={() => handleSubmit()}
                                        disabled={!otpInputValue}
                                    >
                                        {t('auth.signup.otp.submit')}
                                    </button>
                                </>
                            )}
                            <p className='text-[14px] text-[#7C7C7C] w-[100%] text-center !mt-8'>
                                {t('auth.signup.already')} <a onClick={() => navigation('/login')} className="underline text-[#2E3087] font-bold cursor-pointer">
                                    {t('auth.signin.signin')}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignupOtp;