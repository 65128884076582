import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next';

const Graph = () => {
  const { t } = useTranslation();
  const options = {
    chart: {
      type: 'areaspline',
    },
    title: {
      text: '',
      align: 'left'
    },
    legend: {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'top',
      x: 120,
      y: 70,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
    },
    yAxis: {
      title: {
        text: 'No. of tickets'
      }
    },
    tooltip: {
      shared: true,
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.3
      }
    },
    series: [{
      name: 'Today',
      data:
        [
          38000,
          37300,
          37892,
          38564,
          36770,
          36026,
          34978,
          35657,
          35620,
          35971,
          36409,
          36435,
          34643,
          34956,
          33199,
          31136,
          30835,
          31611,
          30666,
          30319,
          31766
        ]
    }, {
      name: 'Last Week',
      data:
        [
          22534,
          23599,
          24533,
          25195,
          25896,
          27635,
          29173,
          32646,
          35686,
          37709,
          39143,
          36829,
          35031,
          36202,
          35140,
          33718,
          37773,
          42556,
          43820,
          46445,
          50048
        ]
    }]
  }

  return (
    <div className='flex flex-col justify-start items-start w-[100%] bg-white'>
      <div className='w-[100%] uppercase text-[16px] px-6 py-4 font-[600] border-b-[1px] border-[#E9EFF3]'>
        {t('connections.chatbottickets')}
      </div>
      <div className='p-6 w-[100%]'>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          containerProps={{ className: 'w-[100%]' }}
        />
      </div>
    </div>
  )
}

export default Graph;