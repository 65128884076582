import React, { useState, useEffect, useReducer, useContext } from "react";
import Sidebar from './components/Sidebar';
import Headerbar from './components/Headerbar';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import TableRowSkeleton from './components/material/TableRowSkeleton';
import Avatar from "@material-ui/core/Avatar";
import { BiDownArrowCircle } from 'react-icons/bi';
import ContactModal from './components/contact/ContactModal';
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import api from '../core/api';
import openSocket from '../core/socket-io';
import ConfirmationModal from "./components/global/ConfirmationModal";
import { AuthContext } from "../core/contexts/AuthProvider";


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    width: '100%',
    ...theme.scrollbarStyles,
    border: 0
  },
  tableRoot: {
    "& .MuiTableCell-head": {
      color: "#2E3087",
      backgroundColor: "#E8F3FA",
      fontWeight: 700,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:first-child": {
        borderLeft: 0,
        borderTopLeftRadius: 8
      },
      "&:last-child": {
        borderTopRightRadius: 8
      }
    },
    "& .MuiTableCell-body": {
      color: "#263238",
      fontWeight: 400,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:last-child": {
        borderRight: '1px solid #E0ECF2'
      }
    },
  }
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Contact = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const { user, handleLogout } = useContext(AuthContext);
  const classes = useStyles();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [contacts, dispatch] = useReducer(reducer, []);


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          console.log("err during get the contacts: ", err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("contact", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  }

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import");
      navigation(0);
    } catch (err) {
      console.log("err during import the contacts: ", err);
    }
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      console.log("Contact remove successed");
    } catch (err) {
      console.log("err during remove contact: ", err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  }

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  }

  return (
    <div className='w-[100vw] h-[100vh] flex bg-[#F7F8FB] overflow-x-hidden'>
      <Sidebar tab='contact' />
      <div className='flex flex-1 flex-col justify-start items-start'>
        <Headerbar
          title="Contact Management"
        />
        <div className='flex flex-row justify-between items-center w-[100%] bg-white px-6 py-3 border-b-[1px] border-[#E9EFF3]'>
          <button
            className='bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]'
            onClick={handleOpenContactModal}
          >
            + Add Contact
          </button>
          <div className='flex flex-row justify-end items-center space-x-2 text-[14px] cursor-pointer'
            onClick={() => setConfirmOpen(true)}
          >
            <BiDownArrowCircle className='text-[22px]' />
            <span>Import Contacts</span>
          </div>
        </div>
        <div className='px-10 py-5 flex flex-col justify-start items-start w-[100%] space-y-12'>
          <ConfirmationModal
            title={
              deletingContact
                ? `${t('contacts.modal.delete')} ${deletingContact.name
                }?`
                : `${t('contacts.modal.import')}`
            }
            open={confirmOpen}
            onClose={setConfirmOpen}
            onConfirm={(e) =>
              deletingContact
                ? handleDeleteContact(deletingContact.id)
                : handleimportContact()
            }
          >
            {deletingContact
              ? t('contacts.modal.deleteContent')
              : t('contacts.modal.importContent')
            }
          </ConfirmationModal>
          <ContactModal
            open={contactModalOpen}
            onClose={handleCloseContactModal}
            aria-labelledby="form-dialog-title"
            contactId={selectedContactId}
          />
          <Paper
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table aria-label="user table" className={classes.tableRoot}>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="5%">No</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Phone Number</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Profile</TableCell>
                  <TableCell align="left" width="10%">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {contacts.map((contact, index) => (
                    <TableRow key={contact.id}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        <div className="flex flex-row justify-start items-center space-x-2">
                          <Avatar src={contact.profilePicUrl} style={{ width: 32, height: 32 }} />
                          <span>{contact.name}</span>
                        </div>
                      </TableCell>
                      <TableCell align="left">{contact.number}</TableCell>
                      <TableCell align="left">{contact.email}</TableCell>
                      <TableCell align="left">{contact.profile}</TableCell>
                      <TableCell align="left">
                        <IconButton
                          size="small"
                          onClick={() => handleEditContact(contact)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton columns={6} />}
                </>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    </div>
  )
}

export default Contact;