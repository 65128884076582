import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import openSocket from "../../socket-io";
import api from "../../api";
import { toast } from "react-toastify";

const useAuth = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [err, setErr] = useState(null);

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("salla_app_token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
        setIsAuth(true);
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        const { data } = await api.post("/auth/refresh_token");
        if (data) {
          localStorage.setItem("salla_app_token", data.token);
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
        }
        return api(originalRequest);
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("salla_app_token");
        api.defaults.headers.Authorization = undefined;
        setIsAuth(false);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const token = localStorage.getItem("salla_app_token");

    const getQueryParams = (url) => {
      const queryParams = new URLSearchParams(url.search);
      const params = {};
      queryParams.forEach((value, key) => {
        params[key] = value;
      });
      return params;
    };

    const login = async (userData) => {
      const { data } = await api.post("/auth/login", userData);

      setIsAuth(true);

      localStorage.setItem("salla_app_token", data.token);
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setUser(data.user);
      navigation("/dashboard");

      setLoading(false);
      console.log("success");
    };

    (async () => {
      if (token) {
        try {
          const { data } = await api.post("/auth/refresh_token");
          api.defaults.headers.Authorization = `Bearer ${data.token}`;
          setIsAuth(true);
          setUser(data.user);
        } catch (err) {
          console.log("err: ", err);
        }
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");

        if (location.pathname.includes("register")) {
          navigation("/register");
        } else if (code) {
          try {
            const { data } = await api.post("/oauth/check", { code: code });
            login(data.user);
          } catch (err) {
            console.log("err: ", err);
          }
        } else {
          navigation("/login");
        }
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("user", (data) => {
      if (data.action === "update" && data.user.id === user.id) {
        setUser(data.user);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const handleLogin = async (userData) => {
    setLoading(true);
    try {
      const { data } = await api.post("/auth/login", userData);
      setIsAuth(true);
      localStorage.setItem("salla_app_token", data.token);
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setUser(data.user);
      navigation("/dashboard");
      setLoading(false);
    } catch (err) {
      setErr("Invalid Credential");
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await api.delete("/auth/logout");
      setIsAuth(false);
      setUser({});
      localStorage.removeItem("salla_app_token");
      api.defaults.headers.Authorization = undefined;
      setLoading(false);
      navigation("/login");
    } catch (err) {
      console.log("err: ", err);
      setLoading(false);
    }
  };
  return { isAuth, user, loading, err, setErr, handleLogin, handleLogout };
};

export default useAuth;
