import React, { useState, useEffect, useContext, useReducer, useRef } from 'react';
import Sidebar from './components/Sidebar';
import Headerbar from './components/Headerbar';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import TableRowSkeleton from './components/material/TableRowSkeleton';
import AnswerModal from './components/answer/AnswerModal';
import useAuth from "../core/contexts/hooks/useAuth.jsx";

import api from '../core/api';
import openSocket from '../core/socket-io';
import ConfirmationModal from './components/global/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import XLSX from 'sheetjs-style';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    width: '100%',
    ...theme.scrollbarStyles,
    border: 0
  },
  tableRoot: {
    "& .MuiTableCell-head": {
      color: "#2E3087",
      backgroundColor: "#E8F3FA",
      fontWeight: 700,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:first-child": {
        borderLeft: 0,
        borderTopLeftRadius: 8
      },
      "&:last-child": {
        borderTopRightRadius: 8
      }
    },
    "& .MuiTableCell-body": {
      color: "#263238",
      fontWeight: 400,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:last-child": {
        borderRight: '1px solid #E0ECF2'
      }
    },
  }
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_ANSWERS") {
    const answers = action.payload;
    const newAnswers = [];
    answers.forEach((answer) => {
      const answerIndex = state.findIndex((a) => a.id === answer.id);
      if (answerIndex !== -1) {
        state[answerIndex] = answer;
      } else {
        newAnswers.push(answer);
      }
    });

    return [...state, ...newAnswers];
  }

  if (action.type === "UPDATE_ANSWERS") {
    const answer = action.payload;
    const answerIndex = state.findIndex((a) => a.id === answer.id);

    if (answerIndex !== -1) {
      state[answerIndex] = answer;
      return [...state];
    } else {
      return [answer, ...state];
    }
  }

  if (action.type === "DELETE_ANSWER") {
    const answerId = action.payload;

    const answerIndex = state.findIndex((a) => a.id === answerId);
    if (answerIndex !== -1) {
      state.splice(answerIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Answer = () => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [answerModalOpen, setAnswerModalOpen] = useState(false);
  const [answers, dispatch] = useReducer(reducer, []);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [deletingAnswer, setDeletingAnswer] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
	const { user } = useAuth();
  const [nonExistingQA, setNonExistingQA] = useState(user.nonExistingQA || '');

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    if (user) {
      setNonExistingQA(user.nonExistingQA || '');
    }
  }, [user])

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchAnswers = async () => {
        try {
          const { data } = await api.get("/answer/", {
            params: { searchParam, pageNumber },
          });
          console.log("data: ", data);
          dispatch({ type: "LOAD_ANSWERS", payload: data });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          // toastError(err);
          console.log("err during get answers: ", err);
        }
      };
      fetchAnswers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("answer", (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_ANSWERS", payload: data.answer });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_ANSWER", payload: +data.answerId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleDeleteAnswer = async (answerId) => {
    try {
      await api.delete(`/answer/${answerId}`);
      console.log("answer deleted");
    } catch (err) {
      // toastError(err);
      console.log("err during delete answer: ", err);
    }
    setDeletingAnswer(null);
    setSearchParam("");
    setPageNumber(1);
  };


  const handleEditAnswer = (answer) => {
    setSelectedAnswer(answer);
    setAnswerModalOpen(true);
  }

  const handleSaveNonExistingAnswer = async () => {
    if (nonExistingQA.length === 0) {
      toast.warning("Please input non-existing answer.");
      return;
    }
    try {
      await api.put("/answer/non-existing", {
        nonExistingQA,
      });
      toast.success("Non-existing answer saved successfully.");
    } catch(err) {
      toast.error("There is problem on saving non-existing answer.");
    }
  }

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleOpenAnswerModal = () => {
    setSelectedAnswer(null);
    setAnswerModalOpen(true);
  }

  const handleCloseAnswerModal = () => {
    setAnswerModalOpen(false);
  }

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleChangeFile = function (e) {
    e.preventDefault();
    if (e.target.files) {
      setExcelFile(e.target.files);
    }
  };

  const handleAddAnswer = () => {
    if (excelFile) {
      const file = excelFile[0];
      const reader = new FileReader();
      reader.onload = async function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: 'binary' });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

        for (let i = 0; i < dataParse.length; i++) {
          if (dataParse[i][0] && dataParse[i][0].length > 0 && dataParse[i][1] && dataParse[i][1].length > 0) {
            await api.post("/answer", {
              question: dataParse[i][0],
              answer: dataParse[i][1],
              file: ''
            });
          } else {
            toast('There are incorrect data from excel file, we ignored those!', toast.TYPE.WARNING);
          }
        }

        setExcelFile(null);
      };
      reader.readAsBinaryString(file)
    } else {
      console.log("please upload the excel file");
    }
  }

  return (
    <div className='w-[100vw] h-[100vh] flex bg-[#F7F8FB] overflow-x-hidden'>
      <Sidebar tab='answer' />
      <div className='flex flex-1 flex-col justify-start items-start'>
        <Headerbar
          title={t('answers.title')}
        />
        <div className='px-10 py-5 flex flex-col justify-start items-start w-[100%] space-y-12'>
          <div className='flex flex-col justify-start items-start w-[100%] text-[#263238] font-[500] space-y-4'>
            <p className='text-[24px] leading-[32px]'>
              {t('answers.subtitle')}
            </p>
            <div className='flex flex-row justify-between items-end w-[100%]'>
              <div className='max-w-[630px] text-[16px]'>
                <span>
                  {t('answers.subtext')}
                </span>
              </div>
              <div className='flex flex-row justify-end items-center space-x-4 text-[14px]'>
                <input
                  type='text'
                  placeholder='Search...'
                  className='px-4 py-2 bg-transparent border-[1px] border-[#DADBDD] rounded-[4px]'
                />
                <button
                  className='bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]'
                  onClick={handleOpenAnswerModal}
                >
                  {t('answers.buttons.add')}
                </button>
                <input
                  ref={inputRef}
                  type='file'
                  id='input-file-upload'
                  onChange={handleChangeFile}
                  accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv'
                />
                <button
                  className='bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]'
                  onClick={() => {
                    handleAddAnswer();
                  }}
                >
                  {t('answers.buttons.upload')}
                </button>
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-center items-center w-[100%]'>
            <div className='flex flex-col justify-start items-center w-[100%]'>
              <div className='flex flex-col text-[16px] whitespace-nowrap w-full mb-3'>
                <span>
                  {t('answers.nonExisting')}
                </span>
              </div>
              <div className='flex flex-col justify-start items-end space-x-4 text-[14px] ml-3 w-full'>
                <textarea
                  type='text'
                  value={nonExistingQA}
                  onChange={(e) => setNonExistingQA(e.target.value)}
                  placeholder={t('answers.nonExistingContent')}
                  className='px-4 py-2 bg-transparent border-[1px] border-[#DADBDD] rounded-[4px] w-full mb-3 h-20'
                />
                <button
                  className='bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]'
                  onClick={handleSaveNonExistingAnswer}
                >
                  {t('answers.modal.buttons.confirm')}
                </button>
              </div>
            </div>
          </div>
          <ConfirmationModal
            title={
              deletingAnswer &&
              `${t('answers.modal.delete')}?`
            }
            open={confirmModalOpen}
            onClose={setConfirmModalOpen}
            onConfirm={() => handleDeleteAnswer(deletingAnswer.id)}
          >
            {t('answers.modal.deleteContent')}
          </ConfirmationModal>
          <AnswerModal
            open={answerModalOpen}
            onClose={handleCloseAnswerModal}
            aria-labelledby="form-dialog-title"
            answerId={selectedAnswer && selectedAnswer.id}
          />
          <Paper
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table aria-label="user table" className={classes.tableRoot}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('answers.table.no')}</TableCell>
                  <TableCell align="center">{t('answers.table.question')}</TableCell>
                  <TableCell align="center">{t('answers.table.answer')}</TableCell>
                  <TableCell align="center">{t('answers.table.link')}</TableCell>
                  <TableCell align="right">{t('answers.table.actions')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {answers.map((answer, index) => {
                    let file = answer.file;
                    let fileNameArr = file?.split(";") || [];
                    return <TableRow key={answer.id}>
                      <TableCell align="left" className='w-[10%]'>{index + 1}</TableCell>
                      <TableCell align="left">{answer.question.replaceAll(';', ',')}</TableCell>
                      <TableCell align="left">{answer.answer}</TableCell>
                      <TableCell align="center" className='w-[20%]'>
                        {fileNameArr.length > 1 ?
                          fileNameArr.map((fileName, idx) => {
                            return (
                              <a
                                key={idx}
                                href={`${process.env.REACT_APP_API_URL}/public/${fileName}`}
                                target='_blank'
                                className='underline'
                              >
                                <p>{fileName}</p>
                              </a>
                            )
                          }) :
                          null
                        }
                      </TableCell>
                      <TableCell align="right" className='w-[10%]'>
                        <IconButton
                          size="small"
                          onClick={() => handleEditAnswer(answer)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingAnswer(answer);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  })}
                  {loading ?
                    <TableRowSkeleton columns={5} /> :
                    answers.length === 0 ?
                      <TableRow>
                        <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                          There is no data.
                        </TableCell>
                      </TableRow>
                      : null
                  }
                </>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    </div>
  )
}

export default Answer;