import React, { useState, useCallback, useContext, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import Headerbar from './components/Headerbar';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import TableRowSkeleton from './components/material/TableRowSkeleton';
import { green } from "@material-ui/core/colors";
import WhatsAppModal from './components/connection/WhatsAppModal';

import { AuthContext } from '../core/contexts/AuthProvider';
import { WhatsAppsContext } from '../core/contexts/WhatsAppsProvider';
import api from '../core/api';
import ConfirmationModal from './components/global/ConfirmationModal';
import QrcodeModal from './components/global/QrcodeModal';
import { useTranslation } from 'react-i18next';

import {
  Edit,
  CheckCircle,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
  CropFree,
  DeleteOutline,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    width: '100%',
    ...theme.scrollbarStyles,
    border: 0
  },
  tableRoot: {
    "& .MuiTableCell-head": {
      color: "#2E3087",
      backgroundColor: "#E8F3FA",
      fontWeight: 700,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:first-child": {
        borderLeft: 0,
        borderTopLeftRadius: 8
      },
      "&:last-child": {
        borderTopRightRadius: 8
      }
    },
    "& .MuiTableCell-body": {
      color: "#263238",
      fontWeight: 400,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:last-child": {
        borderRight: '1px solid #E0ECF2'
      }
    },
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tooltip: {
    backgroundColor: "rgba(30,38,47,1)",
    color: "white",
    fontSize: 10,
    maxWidth: 300,
  },
  arrow: {
    "&:before": {
      border: "1px solid rgba(30,38,47,1)"
    },
    color: 'rgba(30,38,47,1)'
  },
  tooltipPopper: {
    textAlign: "center",
  },
  buttonProgress: {
    color: green[500],
  },
  tooltipText: {
    fontSize: 12
  }
}));

const CustomToolTip = ({ title, content, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        arrow: classes.arrow,
        tooltip: classes.tooltip,
        popper: classes.tooltipPopper,
      }}
      title={
        <React.Fragment>
          <Typography gutterBottom color="inherit" className={classes.tooltipText}>
            {title}
          </Typography>
          {content && <Typography className={classes.tooltipText}>{content}</Typography>}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
};

const Connection = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const { user, handleLogout } = useContext(AuthContext);
  const { whatsApps, loading } = useContext(WhatsAppsContext);

  const confirmationModalInitialState = {
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
    open: false,
  };

  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );

  const handleStartWhatsAppSession = async whatsAppId => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      console.log("err during start the whatsapp session: ", err);
    }
  };

  const handleRequestNewQrCode = async whatsAppId => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      console.log("err during the request next qr code: ", err);
    }
  };

  const handleOpenQrModal = whatsApp => {
    setSelectedWhatsApp(whatsApp);
    setQrModalOpen(true);
  };

  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
  }, [setQrModalOpen, setSelectedWhatsApp]);

  const handleEditWhatsApp = whatsApp => {
    setSelectedWhatsApp(whatsApp);
    setWhatsAppModalOpen(true);
  };

  const handleOpenWhatsAppModal = () => {
    setSelectedWhatsApp(null);
    setWhatsAppModalOpen(true);
  };

  const handleCloseWhatsAppModal = useCallback(() => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, setWhatsAppModalOpen]);

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action: action,
        title: t('connections.modal.disconnect'),
        message: t('connections.modal.disconnectContent'),
        whatsAppId: whatsAppId,
      });
    }

    if (action === "delete") {
      setConfirmModalInfo({
        action: action,
        title: t('connections.modal.delete'),
        message: t('connections.modal.deleteContent'),
        whatsAppId: whatsAppId,
      });
    }
    setConfirmModalOpen(true);
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "disconnect") {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        console.log("err during submitting the confirm modal: ", err);
      }
    }

    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
        console.log("whatsapp removed success");
      } catch (err) {
        console.log("err during the confirm modal: ", err);
      }
    }

    setConfirmModalInfo(confirmationModalInitialState);
  };

  const renderActionButtons = whatsApp => {
    console.log("whatsapp status: ", whatsApp.status);
    return (
      <>
        {whatsApp.status === "qrcode" && (
          <div
            className='bg-[#2E3087] rounded-[4px] flex flex-row justify-center items-center px-2 py-1 text-white w-[80px] cursor-pointer'
            onClick={() => handleOpenQrModal(whatsApp)}
          >
            QR code
          </div>
        )}
        {whatsApp.status === "DISCONNECTED" && (
          <div className='flex flex-row justify-start items-center space-x-4'>
            <div
              className='border-[#CACBDE] border-[1px] bg-transparent rounded-[4px] flex flex-row justify-center items-center px-2 py-1 text-[#2E3087]'
              onClick={() => handleStartWhatsAppSession(whatsApp.id)}
            >
              Try Again
            </div>
            <div
              className='border-[#CACBDE] border-[1px] bg-transparent rounded-[4px] flex flex-row justify-center items-center px-2 py-1 text-[#2E3087]'
              onClick={() => handleRequestNewQrCode(whatsApp.id)}
            >
              New QR CODE
            </div>
          </div>
        )}
        {(whatsApp.status === "CONNECTED" ||
          whatsApp.status === "PAIRING" ||
          whatsApp.status === "TIMEOUT") && (
            <div
              className='border-[#CACBDE] border-[1px] bg-transparent rounded-[4px] flex flex-row justify-center items-center px-2 py-1 text-[#2E3087] w-[100px]'
              onClick={() => handleOpenConfirmationModal("disconnect", whatsApp.id)}
            >
              Disconnect
            </div>
          )}
        {whatsApp.status === "OPENING" && (
          <Button size="small" variant="outlined" disabled color="default">
            Connecting
          </Button>
        )}
      </>
    );
  };

  const renderStatusToolTips = whatsApp => {
    return (
      <div className={classes.customTableCell}>
        {whatsApp.status === "DISCONNECTED" && (
          <CustomToolTip
            title={'Failed to start WhatsApp session'}
            content={'Make sure your cell phone is connected to the internet and try again, or request a new QR Code'}
          >
            <SignalCellularConnectedNoInternet0Bar color="secondary" />
          </CustomToolTip>
        )}
        {whatsApp.status === "OPENING" && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {whatsApp.status === "qrcode" && (
          <CustomToolTip
            title={'Waiting for QR Code read'}
            content={"Click on 'QR CODE' button and read the QR Code with your cell phone to start session"}
          >
            <CropFree />
          </CustomToolTip>
        )}
        {whatsApp.status === "CONNECTED" && (
          <CustomToolTip title={'Connection established'}>
            <SignalCellular4Bar style={{ color: green[500] }} />
          </CustomToolTip>
        )}
        {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
          <CustomToolTip
            title={'Connection with cell phone has been lost'}
            content={"Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode"}
          >
            <SignalCellularConnectedNoInternet2Bar color="secondary" />
          </CustomToolTip>
        )}
      </div>
    );
  };
  console.log("qrModalOpen: ", qrModalOpen);
  console.log("selectedWhatsApp: ", selectedWhatsApp);
  return (
    <div className='w-[100vw] h-[100vh] flex bg-[#F7F8FB] overflow-x-hidden'>
      <Sidebar tab='connection' />
      <div className='flex flex-1 flex-col justify-start items-start'>
        <Headerbar
          title="Connection Management"
        />
        <div className='flex flex-row justify-between items-center w-[100%] bg-white px-6 py-3 border-b-[1px] border-[#E9EFF3]'>
          <input
            type='text'
            placeholder='Search...'
            className='px-4 py-2 bg-transparent border-[1px] border-[#DADBDD] rounded-[4px]'
          />
          <div className='flex flex-row justify-end items-center space-x-2 text-[14px] cursor-pointer'>
            <button
              className='bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]'
              onClick={handleOpenWhatsAppModal}
            >
              + Add WhatsApp
            </button>
          </div>
        </div>
        <div className='px-10 py-5 flex flex-col justify-start items-start w-[100%] space-y-12'>
          <ConfirmationModal
            title={confirmModalInfo.title}
            open={confirmModalOpen}
            onClose={setConfirmModalOpen}
            onConfirm={handleSubmitConfirmationModal}
          >
            {confirmModalInfo.message}
          </ConfirmationModal>
          <QrcodeModal
            open={qrModalOpen}
            onClose={handleCloseQrModal}
            whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
          />
          <WhatsAppModal
            open={whatsAppModalOpen}
            onClose={handleCloseWhatsAppModal}
            whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
          />
          <Paper
            className={classes.mainPaper}
            variant="outlined"
          >
            <Table aria-label="user table" className={classes.tableRoot}>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="5%">No</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Session</TableCell>
                  <TableCell align="left">Last Update</TableCell>
                  <TableCell align="left">Default</TableCell>
                  <TableCell align="left" width="10%">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {whatsApps.map((connection, index) => (
                    <TableRow key={connection.id}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">{connection.name}</TableCell>
                      <TableCell align="left">
                        {renderStatusToolTips(connection)}
                      </TableCell>
                      <TableCell align="left">
                        {renderActionButtons(connection)}
                      </TableCell>
                      <TableCell align="left">{connection.updatedAt}</TableCell>
                      <TableCell align="center">
                        {connection.isDefault && (
                          <div className={classes.customTableCell}>
                            <CheckCircle style={{ color: green[500] }} />
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          size="small"
                          onClick={() => handleEditWhatsApp(connection)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            // setConfirmModalOpen(true);
                            handleOpenConfirmationModal("delete", connection.id);

                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton columns={7} />}
                </>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    </div>
  )
}

export default Connection;