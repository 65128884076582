import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useTranslation } from 'react-i18next';
import CustomButton from "../global/CustomButton";
import api from "../../../core/api";
import ReactFileReader from 'react-file-reader';
import XLSX from 'sheetjs-style';
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "flex-start",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  actions: {
    padding: '20px 20px'
  },
  formControl: {
    minWidth: 120,
  },
  formControlRoot: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    flexWrap: "wrap",
    flexDirection: "row",
    border: '2px solid lightgray',
    padding: 4,
    borderRadius: '4px',
    "&> div.container": {
      gap: "6px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    },
    "& > div.container > span": {
      backgroundColor: "gray",
      padding: "1px 3px",
      borderRadius: "4px"
    }
  }
}));

const initialState = {
  name: "",
  content: ""
}

const BroadcastModal = ({ open, onClose, answerId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [uploadFileName, setUploadFileName] = useState(null);
  const [currValue, setCurrValue] = useState("");
  const [progress, setProgress] = useState(1)
  const [dragActive, setDragActive] = useState(false);
  const [isChecked, setIsChecked] = useState(0);
  const [customPhoneNumberList, setCustomPhoneNumberList] = useState([]);
  const [uploadedSheet, setSheet] = useState([]);

  const AnswerSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, t('yup.short'))
      .max(50, t('yup.long'))
      .required(t('yup.required')),
    content: Yup.string()
      .min(10, t('yup.short'))
      .required(t('yup.required')),
  });

  // useEffect(() => {
  //   const fetchAnswer = async () => {
  //     if (!answerId) return;
  //     try {
  //       const { data } = await api.get(`/answer/${answerId}`);
  //       setAnswer(prevState => {
  //         return { ...prevState, ...data };
  //       });
  //       setQuestions(data.question.split(";").filter(item => item !== ""));
  //     } catch (err) {
  //       // toastError(err);
  //       console.log("err during get answer: ", err);
  //     }
  //   };
  //   fetchAnswer();
  // }, [answerId, open]);

  const handleClose = () => {
    setProgress(1);
    setUploadFileName(null);
    setCustomPhoneNumberList([]);
    setIsChecked(0);
    onClose();
  };

  const handleSaveAnswer = async values => {
    if (!values.content.includes('{name}')) {
      toast('Please add {name} on message content!', { type: 'error' });
      return;
    }
    try {
      if (answerId) {
        // await api.put(`/answer/${answerId}`, {
        //   question: question,
        //   answer: AnswerData.answer,
        //   file: uploadFileName
        // });
      } else {
        await api.post('/broadcast', {
          name: values.name,
          content: values.content,
          file: uploadFileName,
          type: isChecked,
          phoneList: JSON.stringify(customPhoneNumberList),
          uploadedSheet: JSON.stringify(uploadedSheet),
        });
      }
    } catch (err) {
      console.log("err during add the answer: ", err);
    }
    reset();
    setCurrValue("");
    handleClose();
  };

  const reset = () => {
    setProgress(1);
    setUploadFileName(null);
  }

  // file processing related functions
  const handleChangeFile = function (e) {
    e.preventDefault();
    if (e.target.files) {
      handleFile(e.target.files);
    }
  };

  const handleFile = async (files) => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
      console.log(`${i + 1}th file: `, files[i]);
    }

    const result = await api.post(`/broadcast/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (data) => {
        //@ts-ignore Set the progress value to show the progress bar
        const percent = Math.round((data.loaded * 100) / data.total);
        setProgress(percent);
      },
    });

    let fileNames = '';
    if (result?.data.length) {
      for (let i = 0; i < result.data.length; i++) {
        fileNames += result.data[i].filename + ';';
      }
    }

    setUploadFileName(fileNames);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleCSVUpload = (files) => {
    const reader = new FileReader();
    reader.onload = async function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      if (dataParse.length > 0) {
        const phoneNumberList = [];
        const sheet = [];

        for (let i = 1; i < dataParse.length; i++) {
          if (dataParse[i].length >= 3) {
            phoneNumberList.push({
              name: dataParse[i][1],
              phone: dataParse[i][2]
            });
          } else if (dataParse[i].length === 2) {
            phoneNumberList.push({
              name: dataParse[i][0],
              phone: dataParse[i][1]
            });
          } else {
            // toast('Please check the CSV file format!', { type: 'error' });
            continue;
          }
          const item = dataParse[i];
          for (let j = 0; j < 10 - dataParse.length; j++) {
            item.push('');
          }
          sheet.push(item);
        }
        setCustomPhoneNumberList(phoneNumberList);
        setSheet(sheet);
      }
    };
    reader.readAsBinaryString(files[0])
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {answerId
            ? t('broadcast.modal.edit')
            : t('broadcast.modal.add')}
        </DialogTitle>
        <Formik
          initialValues={initialState}
          enableReinitialize={true}
          validationSchema={AnswerSchema}
          onSubmit={(values, actions) => {
            setTimeout(async () => {
              await handleSaveAnswer(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => {
            return <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={t('broadcast.modal.name')}
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
                <Field
                  as={TextField}
                  label={t('broadcast.modal.answer')}
                  name="content"
                  error={touched.content && Boolean(errors.content)}
                  helperText={touched.content && errors.content}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  minRows={5}
                  multiline
                />
                <div className="w-full p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
                  <span className="font-medium pr-2">{"Please add {name} on message content, otherwise you can't broadcast!"}</span>
                </div>
                <div
                  className="relative mt-4 flex h-auto min-h-[115px] items-center justify-center p-4
                  cursor-pointer rounded border-[1px] border-textgrey"
                  onDragEnter={handleDrag}
                >
                  <label
                    id='label-file-upload'
                    htmlFor='input-file-upload'
                    className={`relative mb-0 flex h-[100px] w-[300px] cursor-pointer items-center justify-center ${dragActive ? 'bg-white' : ''
                      }`}
                  >
                    <input
                      className='hidden'
                      ref={inputRef}
                      multiple
                      type='file'
                      id='input-file-upload'
                      onChange={handleChangeFile}
                      accept='image/png, image/gif, image/jpeg, video/mp4, video/x-m4v, video/*, audio/mp3, audio/*;capture=microphone'
                    />
                    {progress > 1 && (
                      <div className='flex w-full flex-col'>
                        <p className='text-center text-black'>{progress} %</p>
                        <div className='my-3 w-full rounded-lg border border-[#424867]'>
                          <div
                            className='progress-bar h-2 rounded-lg bg-darkgrey'
                            style={{ width: `${progress}%` }}
                          />
                        </div>
                      </div>
                    )}
                    {progress === 1 && <p className='text-black'>Drop files here to upload</p>}
                  </label>
                  {dragActive && (
                    <div
                      id='drag-file-element'
                      className='absolute top-0 right-0 bottom-0 left-0 h-full w-full rounded'
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    />
                  )}
                </div>
                <div className="relative mt-4 flex flex-col h-auto min-h-[115px] items-start justify-center p-4
                  cursor-pointer rounded border-[1px] border-textgrey"
                >
                  <MenuItem dense
                    className="block !w-full"
                    onClick={() => setIsChecked(0)}
                  >
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={isChecked === 0}
                    />
                    <ListItemText primary={'All Chats'} />
                  </MenuItem>
                  {/* <MenuItem dense>
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={isChecked === 1}
                      onClick={() => setIsChecked(1)}
                    />
                    <ListItemText primary={'All Registered'} />
                  </MenuItem>
                  <MenuItem dense>
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={isChecked === 2}
                      onClick={() => setIsChecked(2)}
                    />
                    <ListItemText primary={'All non-Registered'} />
                  </MenuItem> */}
                  <MenuItem dense
                    className="block !w-full"
                    onClick={() => setIsChecked(3)}
                  >
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={isChecked === 3}
                    />
                    <ListItemText primary={'Custom from CSV file'} />
                  </MenuItem>
                  {isChecked === 3 &&
                    <div className="flex flex-row justify-start items-center space-x-4 mt-4">
                      <ReactFileReader handleFiles={handleCSVUpload} fileTypes={['.csv', '.xlsx']}>
                        <div className='bg-[#61a7db] text-white px-4 py-1 text-[12px] rounded-[5px] ml-[50px]'>
                          Upload CSV
                        </div>
                      </ReactFileReader>
                      <span className="text-[14px] font-sans font-bold">
                        {customPhoneNumberList.length > 0 && `${customPhoneNumberList.length} Phone numbers imported!`}
                      </span>
                    </div>
                  }
                </div>
              </DialogContent>
              <DialogActions className={classes.actions}>
                <CustomButton
                  text={t('broadcast.modal.buttons.cancel')}
                  onClick={handleClose}
                  type={'cancel'}
                  isSubmitting={isSubmitting}
                />
                <CustomButton
                  text={
                    answerId
                      ? t('broadcast.modal.buttons.edit')
                      : t('broadcast.modal.buttons.add')
                  }
                  type={'confirm'}
                  isSubmitting={isSubmitting}
                />
              </DialogActions>
            </Form>
          }}
        </Formik>
      </Dialog>
    </div>
  )
}

export default BroadcastModal;