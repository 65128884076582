import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';
import CustomButton from "./CustomButton";

const ConfirmationModal = ({ title, children, open, onClose, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-dialog"
      PaperProps={{
        style: {
          position: 'absolute',
          top: 10
        }
      }}
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent dividers>
        <Typography>{children}</Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={() => onClose(false)}
          type={'cancel'}
          text={t('answers.modal.buttons.cancel')}
        />
        <CustomButton
          onClick={() => {
            onClose(false);
            onConfirm();
          }}
          type={'confirm'}
          text={t('answers.modal.buttons.confirm')}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
