import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import openSocket from "socket.io-client";
import Sidebar from "./components/Sidebar";
import Headerbar from "./components/Headerbar";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { green } from "@material-ui/core/colors";
import {
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
  CropFree,
} from "@material-ui/icons";

import Graph from "./components/dashboard/Graph";
import { WhatsAppsContext } from "../core/contexts/WhatsAppsProvider";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../core/contexts/AuthProvider";
import api from "../core/api";
import ConfirmationModal from "./components/global/ConfirmationModal";
import QrcodeContainer from "./components/global/QrcodeContainer";
import QueueModal from "./components/chatbot";
import NewQueueModal from "./components/chatbot-new";
import { Card } from "./Chatbot";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    width: "100%",
    ...theme.scrollbarStyles,
    border: 0,
  },
  tableRoot: {
    "& .MuiTableCell-head": {
      color: "#2E3087",
      backgroundColor: "#E8F3FA",
      fontWeight: 700,
      fontSize: 14,
      borderLeft: "1px solid #E0ECF2",
      padding: "12px 16px",
      "&:first-child": {
        borderLeft: 0,
        borderTopLeftRadius: 8,
      },
      "&:last-child": {
        borderTopRightRadius: 8,
      },
    },
    "& .MuiTableCell-body": {
      color: "#263238",
      fontWeight: 400,
      fontSize: 14,
      borderLeft: "1px solid #E0ECF2",
      padding: "12px 16px",
      "&:last-child": {
        borderRight: "1px solid #E0ECF2",
      },
    },
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tooltip: {
    backgroundColor: "rgba(30,38,47,1)",
    color: "white",
    fontSize: 10,
    maxWidth: 300,
  },
  arrow: {
    "&:before": {
      border: "1px solid rgba(30,38,47,1)",
    },
    color: "rgba(30,38,47,1)",
  },
  tooltipPopper: {
    textAlign: "center",
  },
  buttonProgress: {
    color: green[500],
  },
  tooltipText: {
    fontSize: 12,
  },
}));

const CustomToolTip = ({ title, content, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        arrow: classes.arrow,
        tooltip: classes.tooltip,
        popper: classes.tooltipPopper,
      }}
      title={
        <React.Fragment>
          <Typography
            gutterBottom
            color="inherit"
            className={classes.tooltipText}
          >
            {title}
          </Typography>
          {content && (
            <Typography className={classes.tooltipText}>{content}</Typography>
          )}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
};

const chatbotReducer = (state, action) => {
  if (action.type === "LOAD_QUEUES") {
    const queues = action.payload;
    const newQueues = [];

    queues.forEach((queue) => {
      const queueIndex = state.findIndex((q) => q.id === queue.id);
      if (queueIndex !== -1) {
        state[queueIndex] = queue;
      } else {
        newQueues.push(queue);
      }
    });

    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_QUEUES") {
    const queue = action.payload;
    const queueIndex = state.findIndex((u) => u.id === queue.id);

    if (queueIndex !== -1) {
      state[queueIndex] = queue;
      return [...state];
    } else {
      return [queue, ...state];
    }
  }

  if (action.type === "DELETE_QUEUE") {
    const queueId = action.payload;
    const queueIndex = state.findIndex((q) => q.id === queueId);
    if (queueIndex !== -1) {
      state.splice(queueIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Dashboard = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [chatbotsModalOpen, setChatbotsModalOpen] = useState(false);
  const [chatbotsNewModalOpen, setChatbotsNewModalOpen] = useState(false);
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const [chatbotDeleteModalOpen, setChatbotDeleteModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { whatsApps } = useContext(WhatsAppsContext);
  const [chatbots, dispatch] = useReducer(chatbotReducer, []);
  const confirmationModalInitialState = {
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
    open: false,
  };

  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        dispatch({ type: "LOAD_QUEUES", payload: data });
      } catch (err) {
        console.log("err during get the chatbot: ", err);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_API_URL);

    socket.on("queue", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleCreateWhatsApp = async () => {
    const whatsappData = { name: `${user.id}-whatsapp`, isMultidevice: true };
    try {
      await api.post("/whatsapp", whatsappData);
    } catch (err) {
      console.log("err during save whatsapp: ", err);
    }
  };

  const handleOpenChatbotsModal = () => {
    // setChatbotsModalOpen(true);
    setChatbotsNewModalOpen(true);
  };

  const handleCloseChatbotsModal = useCallback(() => {
    setChatbotsModalOpen(false);
    // setSelectedChatbot(null);
  }, [setChatbotsModalOpen, setSelectedChatbot]);

  const handleCloseChatbotsNewModal = useCallback(() => {
    setChatbotsNewModalOpen(false);
  }, [setChatbotsNewModalOpen, setSelectedChatbot]);

  const handleStartWhatsAppSession = async (whatsAppId) => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      console.log("err during start the whatsapp session: ", err);
    }
  };

  const handleRequestNewQrCode = async (whatsAppId) => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      console.log("err during the request next qr code: ", err);
    }
  };

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action: action,
        title: t("connections.modal.disconnect"),
        message: t("connections.modal.disconnectContent"),
        whatsAppId: whatsAppId,
      });
    }

    if (action === "delete") {
      setConfirmModalInfo({
        action: action,
        title: t("connections.modal.delete"),
        message: t("connections.modal.deleteContent"),
        whatsAppId: whatsAppId,
      });
    }
    setConfirmModalOpen(true);
  };

  const handleChangeAnswerOption = async (e) => {
    const { checked } = e.target;
    console.log("checked", checked);
    try {
      await api.put(`/whatsapp/${whatsApps[0].id}`, {
        ...whatsApps[0],
        isDefault: checked,
      });
    } catch (err) {
      console.log("update answer method in whatsapp failed", err);
    }
  };

  const handleChangeStopBot = async (e) => {
    const { checked } = e.target;
    try {
      await api.put(`/whatsapp/${whatsApps[0].id}`, {
        ...whatsApps[0],
        plugged: checked,
      });
    } catch (err) {
      console.log("update answer method in whatsapp failed", err);
    }
  };

  const renderActionButtons = (whatsApp) => {
    return (
      <>
        {whatsApp.status === "qrcode" && (
          <div className="bg-[#2E3087] rounded-[4px] flex flex-row justify-center items-center px-2 py-1 text-white w-[80px] cursor-pointer text-[12px]">
            {t("connections.table.status.qrcode")}
          </div>
        )}
        {whatsApp.status === "DISCONNECTED" && (
          <div className="flex flex-row justify-start items-center space-x-4">
            <div
              className="border-[#CACBDE] border-[1px] bg-transparent rounded-[4px] flex flex-row justify-center items-center px-2 py-1 text-[#2E3087]"
              onClick={() => handleStartWhatsAppSession(whatsApp.id)}
            >
              {t("connections.table.status.try")}
            </div>
            <div
              className="border-[#CACBDE] border-[1px] bg-transparent rounded-[4px] flex flex-row justify-center items-center px-2 py-1 text-[#2E3087]"
              onClick={() => handleRequestNewQrCode(whatsApp.id)}
            >
              {t("connections.table.status.new")}
            </div>
          </div>
        )}
        {(whatsApp.status === "CONNECTED" ||
          whatsApp.status === "PAIRING" ||
          whatsApp.status === "TIMEOUT") && (
          <div
            className="border-[#CACBDE] border-[1px] bg-transparent rounded-[4px] flex flex-row justify-center items-center px-2 py-1 text-[#2E3087] w-[100px] cursor-pointer"
            onClick={() =>
              handleOpenConfirmationModal("disconnect", whatsApp.id)
            }
          >
            {t("connections.table.status.disconnect")}
          </div>
        )}
        {whatsApp.status === "OPENING" && (
          <Button size="small" variant="outlined" disabled color="default">
            {t("connections.table.status.connecting")}
          </Button>
        )}
      </>
    );
  };

  const renderStatusToolTips = (whatsApp) => {
    return (
      <div className={classes.customTableCell}>
        {whatsApp.status === "DISCONNECTED" && (
          <CustomToolTip
            title={t("connections.tooltip.disconnect.title")}
            content={t("connections.tooltip.disconnect.content")}
          >
            <SignalCellularConnectedNoInternet0Bar color="secondary" />
          </CustomToolTip>
        )}
        {whatsApp.status === "OPENING" && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {whatsApp.status === "qrcode" && (
          <CustomToolTip
            title={t("connections.tooltip.qrcode.title")}
            content={t("connections.tooltip.qrcode.content")}
          >
            <CropFree />
          </CustomToolTip>
        )}
        {whatsApp.status === "CONNECTED" && (
          <CustomToolTip title={t("connections.tooltip.connected.title")}>
            <SignalCellular4Bar style={{ color: green[500] }} />
          </CustomToolTip>
        )}
        {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
          <CustomToolTip
            title={t("connections.tooltip.timeout.title")}
            content={t("connections.tooltip.qrcode.content")}
          >
            <SignalCellularConnectedNoInternet2Bar color="secondary" />
          </CustomToolTip>
        )}
      </div>
    );
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "disconnect") {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        console.log("err during submitting the confirm modal: ", err);
      }
    }

    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
        console.log("whatsapp removed success");
      } catch (err) {
        console.log("err during the confirm modal: ", err);
      }
    }

    setConfirmModalInfo(confirmationModalInitialState);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/queue/${queueId}`);
    } catch (err) {
      console.log("err during remove the chatbot: ", err);
    }
    setSelectedChatbot(null);
  };

  const handleEditQueue = (queue) => {
    setSelectedChatbot(queue);
    setChatbotsModalOpen(true);
  };

  const handleEditNewQueue = (queue) => {
    setSelectedChatbot(queue);
    setChatbotsNewModalOpen(true);
  };

  const handleCloseDeleteChatbotConfirmationModal = () => {
    setSelectedChatbot(null);
    setChatbotDeleteModalOpen(false);
  };

  return (
    <div className="w-[100vw] h-[100vh] flex bg-[#F7F8FB] overflow-x-hidden">
      <Sidebar tab="dashboard" />
      <div className="flex flex-1 flex-col justify-start items-start">
        <Headerbar title={t("header.dashboard")} />
        <div className="px-10 py-5 flex flex-col justify-start items-start w-[100%] space-y-12 overflow-y-auto">
          <div className="flex w-[100%] gap-5">
            <div className="flex flex-col justify-start items-start w-[50%] bg-white">
              <div className="w-[100%] uppercase text-[16px] px-6 py-4 font-[600] border-b-[1px] border-[#E9EFF3]">
                {t("header.connections")}
              </div>
              <div className="w-[100%] p-8 flex flex-col justify-center items-start space-y-8">
                {whatsApps.length === 0 && (
                  <button
                    className="bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]"
                    onClick={handleCreateWhatsApp}
                  >
                    + {t("connections.modal.add")}
                  </button>
                )}
                {whatsApps.length > 0 && (
                  <div className="flex flex-row justify-start items-center space-x-12">
                    <div className="flex flex-col justify-center items-start space-y-4">
                      <div className="flex flex-row justify-start items-center">
                        <span className="text-[12px] mr-2 font-bold text-[#61A7DB]">
                          {t("connections.table.status.title")}:{" "}
                        </span>
                        {renderStatusToolTips(whatsApps[0])}
                      </div>
                      <div className="flex flex-row justify-start items-center">
                        <span className="text-[12px] mr-2 font-bold text-[#61A7DB]">
                          {t("dashboard.enableBot")}
                        </span>
                        <Switch
                          defaultChecked={whatsApps[0].plugged}
                          onChange={(e) => handleChangeStopBot(e)}
                        />
                      </div>
                      <div className="flex flex-row justify-start items-center">
                        <span className="text-[12px] mr-2 font-bold text-[#61A7DB]">
                          {t("dashboard.chatbottree")}
                        </span>
                        <Switch
                          defaultChecked={whatsApps[0].isDefault}
                          onChange={(e) => handleChangeAnswerOption(e)}
                        />
                        <span className="text-[12px] mr-2 font-bold text-[#61A7DB]">
                          {t("dashboard.qalist")}
                        </span>
                      </div>
                      <div className="flex flex-row justify-start items-center">
                        <span className="text-[12px] mr-2 font-bold text-[#61A7DB]">
                          {t("connections.table.session")}:{" "}
                        </span>
                        {renderActionButtons(whatsApps[0])}
                      </div>
                    </div>
                    <div className="">
                      {whatsApps[0].status === "qrcode" && (
                        <QrcodeContainer whatsAppId={whatsApps[0].id} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-[50%] bg-white">
              <div className="w-[100%] uppercase text-[16px] px-6 py-4 font-[600] border-b-[1px] border-[#E9EFF3]">
                {t("header.chatbots")}
              </div>
              <div className="w-[100%] p-8 flex flex-col justify-center items-start space-y-8">
                {chatbots.length > 0 ? (
                  <Card
                    queue={chatbots[0]}
                    handleEdit={() => handleEditQueue(chatbots[0])}
                    handleEditNew={() => handleEditNewQueue(chatbots[0])}
                    handleDelete={() => {
                      setSelectedChatbot(chatbots[0]);
                      setChatbotDeleteModalOpen(true);
                    }}
                  />
                ) : (
                  <button
                    className="bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]"
                    onClick={handleOpenChatbotsModal}
                  >
                    + {t("chatbots.modal.add")}
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* <Graph /> */}
        </div>
      </div>
      <QueueModal
        open={chatbotsModalOpen}
        onClose={handleCloseChatbotsModal}
        queueId={selectedChatbot?.id}
        onShowNewModal={() => {
          handleCloseChatbotsModal();
          if (chatbots.length > 0) {
            handleEditNewQueue(chatbots[0]);
          }
        }}
      />

      <NewQueueModal
        open={chatbotsNewModalOpen}
        onClose={handleCloseChatbotsNewModal}
        queueId={selectedChatbot?.id}
        onShowOldModal={() => {
          handleCloseChatbotsNewModal();
          if (chatbots.length > 0) {
            handleEditQueue(chatbots[0]);
          }
        }}
      />

      <ConfirmationModal
        title={
          selectedChatbot &&
          `${t("chatbots.modal.delete")} ${selectedChatbot.name}?`
        }
        open={chatbotDeleteModalOpen}
        onClose={handleCloseDeleteChatbotConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedChatbot.id)}
      >
        {t("chatbots.modal.deleteContent")}
      </ConfirmationModal>
      <ConfirmationModal
        title={confirmModalInfo.title}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={handleSubmitConfirmationModal}
      >
        {confirmModalInfo.message}
      </ConfirmationModal>
    </div>
  );
};

export default Dashboard;
