import React, { useState, useEffect, useReducer, useContext } from 'react';
import Sidebar from './components/Sidebar';
import Headerbar from './components/Headerbar';
import { MdModeEditOutline, MdOutlineFastfood } from 'react-icons/md';
import { BsTrash } from 'react-icons/bs';
import { AuthContext } from '../core/contexts/AuthProvider';
import api from '../core/api';
import openSocket from "socket.io-client";
import ConfirmationModal from './components/global/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import QueueModal from './components/chatbot';
import NewQueueModal from './components/chatbot-new';
import EmptyChatbotSVG from '../assets/layout/empty_chatbot.svg';

const reducer = (state, action) => {
  if (action.type === "LOAD_QUEUES") {
    const queues = action.payload;
    const newQueues = [];

    queues.forEach((queue) => {
      const queueIndex = state.findIndex((q) => q.id === queue.id);
      if (queueIndex !== -1) {
        state[queueIndex] = queue;
      } else {
        newQueues.push(queue);
      }
    });

    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_QUEUES") {
    const queue = action.payload;
    const queueIndex = state.findIndex((u) => u.id === queue.id);

    if (queueIndex !== -1) {
      state[queueIndex] = queue;
      return [...state];
    } else {
      return [queue, ...state];
    }
  }

  if (action.type === "DELETE_QUEUE") {
    const queueId = action.payload;
    const queueIndex = state.findIndex((q) => q.id === queueId);
    if (queueIndex !== -1) {
      state.splice(queueIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

export const Card = ({ queue, handleEdit, handleEditNew, handleDelete }) => {
  const { t } = useTranslation();
  return (
    <div className='w-auto flex flex-col justify-start items-start bg-white rounded-[8px] p-4 space-y-4'>
      <div className='flex flex-row justify-between items-center w-[100%]'>
        <div className='w-[40px] h-[40px] rounded-full bg-[#D9D9D9]' />
        <BsTrash className='text-[#FF0000] cursor-pointer' onClick={() => handleDelete()} />
      </div>
      <div className='flex flex-row justify-center items-center space-x-2 text-[#FF3D00] rounded-[4px] bg-[#FFE2D9] px-2 py-1'>
        <MdOutlineFastfood className='' />
        <span className='text-[12px]'>ChatBot</span>
      </div>
      <div className='flex flex-col justify-start items-start'>
        {/* <p className='text-[20px] text-[#263238] font-[700]'>{queue.name}</p> */}
        <div className='text-[12px] text-[#374957] max-w-[250px]'>
          {queue.greetingMessage}
        </div>
      </div>
      <div
        className='flex flex-row justify-center items-center space-x-2 text-[14px] text-[#263238] border-[2px] border-[#61A7DB] px-3 py-2 rounded-[8px] cursor-pointer'
        onClick={() => {
          // handleEdit()
          handleEditNew()
        }}
      >
        <MdModeEditOutline />
        <span>{t('chatbots.button')}</span>
      </div>
      {/* <div
        className='flex flex-row justify-center items-center space-x-2 text-[14px] text-[#263238] border-[2px] border-[#61A7DB] px-3 py-2 rounded-[8px] cursor-pointer'
        onClick={() => {
          handleEditNew()
        }}
      >
        <MdModeEditOutline />
        <span>Edit New Template</span>
      </div> */}
    </div>
  )
}

const Chatbot = () => {
  const { t } = useTranslation();
  const [queues, dispatch] = useReducer(reducer, []);

  const [queueModalOpen, setQueueModalOpen] = useState(false);
  const [queueNewModalOpen, setQueueNewModalOpen] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        dispatch({ type: "LOAD_QUEUES", payload: data });
      } catch (err) {
        console.log("err during get the chatbot: ", err);
      }
    })();
  }, []);

  useEffect(() => {

    const socket = openSocket(process.env.REACT_APP_API_URL);

    socket.on("queue", (data) => {
      if (data.action === "update" || data.action === "create") {
        console.log("here");
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenQueueModal = () => {
    setQueueModalOpen(true);
    setSelectedQueue(null);
  };


  const handleCloseQueueModal = () => {
    setQueueModalOpen(false);
    setSelectedQueue(null);
  };

  const handleEditQueue = (queue) => {
    setSelectedQueue(queue);
    setQueueModalOpen(true);
  };

  const handleEditNewQueue = (queue) => {
    setSelectedQueue(queue);
    setQueueNewModalOpen(true);
  }

  const handleCloseNewQueueModal = () => {
    setQueueNewModalOpen(false);
    setSelectedQueue(null);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/queue/${queueId}`);
      console.log("Chatbot deleted successfully!")
    } catch (err) {
      console.log("err during remove the chatbot: ", err);
    }
    setSelectedQueue(null);
  };
  console.log("queues: ", queues);
  return (
    <div className='w-[100vw] h-[100vh] flex bg-[#F7F8FB] overflow-x-hidden'>
      <Sidebar tab='chatbot' />
      <div className='flex flex-1 flex-col justify-start items-start'>
        <Headerbar
          title={t('chatbots.title')}
        />
        <ConfirmationModal
          title={
            selectedQueue &&
            `${t('chatbots.modal.delete')} ${selectedQueue.name
            }?`
          }
          open={confirmModalOpen}
          onClose={handleCloseConfirmationModal}
          onConfirm={() => handleDeleteQueue(selectedQueue.id)}
        >
          {t('chatbots.modal.deleteContent')}
        </ConfirmationModal>
        <QueueModal
          open={queueModalOpen}
          onClose={handleCloseQueueModal}
          queueId={selectedQueue?.id}
          onEdit={(res) => {
            if (res) {
              setTimeout(() => {
                handleEditQueue(res)
              }, 200)
            }
          }}
        />

        <NewQueueModal
          open={queueNewModalOpen}
          onClose={handleCloseNewQueueModal}
          queueId={selectedQueue?.id}
          onEdit={(res) => {
            if (res) {
              setTimeout(() => {
                handleEditNewQueue(res)
              }, 200)
            }
          }}
        />

        <div className='flex flex-row justify-between items-center w-[100%] bg-white px-6 border-b-[1px] border-[#E9EFF3] overflow-y-scroll'>
          <div className='flex flex-row justify-start items-center text-[#374957] text-[16px] space-x-8'>
            {/* <div className='cursor-pointer'>My Bots</div> */}
            <div className='py-4'></div>
          </div>
          <div className='flex flex-row justify-end items-center space-x-2 text-[14px] cursor-pointer'>
            {/* <input
              type='text'
              placeholder='Search...'
              className='px-4 py-2 bg-transparent border-[1px] border-[#DADBDD] rounded-[4px]'
            /> */}
            <button
              className='bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB] my-4'
              onClick={() => handleOpenQueueModal()}
            >
              {t('chatbots.modal.add')}
            </button>
          </div>
        </div>
        <div className='px-10 py-5 flex flex-col justify-start items-start w-[100%] space-y-12'>
          {queues.length !== 0 ?
            <div className='grid grid-cols-4 w-[100%] gap-8'>
              {queues.map((queue, index) => {
                return (
                  <Card
                    key={index}
                    queue={queue}
                    handleEdit={() => handleEditQueue(queue)}
                    handleEditNew={() => handleEditNewQueue(queue)}
                    handleDelete={() => {
                      setSelectedQueue(queue);
                      setConfirmModalOpen(true);
                    }}
                  />
                )
              })}
            </div> :
            <div className='w-full flex flex-col justify-center items-center h-[70vh] space-y-8'>
              <img src={EmptyChatbotSVG} alt='no chatbot' />
              <p className='text-[20px] text-[#2E3087] font-[600] leading-[26px]'>
                {t('chatbots.nobot')}
              </p>
              <button
                className='bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]'
                onClick={() => handleOpenQueueModal()}
              >
                {t('chatbots.modal.add')}
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Chatbot;