import React, { useState, useEffect, useContext, useReducer } from 'react';
import Sidebar from './components/Sidebar';
import Headerbar from './components/Headerbar';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import TableRowSkeleton from './components/material/TableRowSkeleton';
import UserModal from './components/user/UserModal';
import { AuthContext } from '../core/contexts/AuthProvider';
import api from '../core/api';
import openSocket from '../core/socket-io';
import ConfirmationModal from './components/global/ConfirmationModal';
import { useTranslation } from 'react-i18next';

const reducer = (state, action) => {
  if (action.type === "LOAD_USERS") {
    const users = action.payload;
    const newUsers = [];

    users.forEach((user) => {
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
      } else {
        newUsers.push(user);
      }
    });

    return [...state, ...newUsers];
  }

  if (action.type === "UPDATE_USERS") {
    const user = action.payload;
    const userIndex = state.findIndex((u) => u.id === user.id);

    if (userIndex !== -1) {
      state[userIndex] = user;
      return [...state];
    } else {
      return [user, ...state];
    }
  }

  if (action.type === "DELETE_USER") {
    const userId = action.payload;

    const userIndex = state.findIndex((u) => u.id === userId);
    if (userIndex !== -1) {
      state.splice(userIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    width: '100%',
    ...theme.scrollbarStyles,
    border: 0
  },
  tableRoot: {
    "& .MuiTableCell-head": {
      color: "#2E3087",
      backgroundColor: "#E8F3FA",
      fontWeight: 700,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:first-child": {
        borderLeft: 0,
        borderTopLeftRadius: 8
      },
      "&:last-child": {
        borderTopRightRadius: 8
      }
    },
    "& .MuiTableCell-body": {
      color: "#263238",
      fontWeight: 400,
      fontSize: 14,
      borderLeft: '1px solid #E0ECF2',
      padding: '12px 16px',
      "&:last-child": {
        borderRight: '1px solid #E0ECF2'
      }
    },
  }
}));

const User = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);

  const { handleLogout } = useContext(AuthContext);
  const [users, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_USERS", payload: data.users });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          // toastError(err);
          console.log("err during get users: ", err);
        }
      };
      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_USERS", payload: data.user });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.userId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserModalOpen(true);
  }

  const handleDeleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
      console.log("user deleted");
    } catch (err) {
      // toastError(err);
      console.log("err during delete user: ", err);
    }
    setDeletingUser(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleOpenUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(false);
  };

  return (
    <div className='w-[100vw] h-[100vh] flex bg-[#F7F8FB] overflow-x-hidden'>
      <Sidebar tab='user' />
      <div className='flex flex-1 flex-col justify-start items-start'>
        <Headerbar
          title="User Management"
        />
        <div className='flex flex-row justify-between items-center w-[100%] bg-white px-6 py-3 border-b-[1px] border-[#E9EFF3]'>
          <span className='text-[20px] leading-[26px] font-[500]'>
            Total user: 5
          </span>
          <div className='flex flex-row justify-end items-center space-x-4 text-[14px]'>
            <input
              type='text'
              placeholder='Search...'
              className='px-4 py-2 bg-transparent border-[1px] border-[#DADBDD] rounded-[4px]'
            />
            <button
              className='bg-[#61A7DB] rounded-[4px] text-white px-4 py-2 border-[1px] border-[#61A7DB]'
              onClick={handleOpenUserModal}
            >
              + Add User
            </button>
          </div>
        </div>
        <div className='px-10 py-5 flex flex-col justify-start items-start w-[100%] space-y-12'>
          <ConfirmationModal
            title={
              deletingUser &&
              `${t('users.modal.delete')} ${deletingUser.name
              }?`
            }
            open={confirmModalOpen}
            onClose={setConfirmModalOpen}
            onConfirm={() => handleDeleteUser(deletingUser.id)}
          >
            {t('users.modal.deleteContent')}
          </ConfirmationModal>
          <UserModal
            open={userModalOpen}
            onClose={handleCloseUserModal}
            aria-labelledby="form-dialog-title"
            userId={selectedUser && selectedUser.id}
          />
          <Paper
            className={classes.mainPaper}
            variant="outlined"
            onScroll={handleScroll}
          >
            <Table aria-label="user table" className={classes.tableRoot}>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="5%">No</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Profile</TableCell>
                  <TableCell align="left" width="10%">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {users.map((user, index) => (
                    <TableRow key={user.id}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">{user.name}</TableCell>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="left">{user.profile}</TableCell>
                      <TableCell align="left">
                        <IconButton
                          size="small"
                          onClick={() => handleEditUser(user)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingUser(user);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton columns={5} />}
                </>
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    </div>
  )
}

export default User;