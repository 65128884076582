import React, { useContext } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from './pages/Landing';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import SignupOtp from './pages/SignupOtp';
import ConnectProvider from './provider/ConnectProvider';
import { ToastContainer } from "react-toastify"
import "./index.css";
// Multiple Language
import './i18n';

import Dashboard from './pages/Dashboard';
import Inbox from './pages/Inbox';
import Answer from './pages/Answer';
import Chatbot from './pages/Chatbot';
import Contact from './pages/Contact';
import User from './pages/User';
import Connection from './pages/Connection';
import Broadcast from './pages/Broadcast';
import Priceplan from './pages/Priceplan';
import Event from './pages/Event';



import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from "jss";
import rtl from "jss-rtl";
import { ltrTheme, rtlTheme } from './provider/Styles';

import { UserDataProvider } from './core/contexts/UserDataProvider';
import { AuthContext, AuthProvider } from './core/contexts/AuthProvider';
import { WhatsAppsProvider } from './core/contexts/WhatsAppsProvider';

const root = ReactDOM.createRoot(document.getElementById("root"));
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const RouteContainer = () => {
  return (
    <Router>
      <UserDataProvider>
      <AuthProvider>
        <ToastContainer />
        <StylesProvider jss={jss}>
          <Routing />
        </StylesProvider>
      </AuthProvider>
      </UserDataProvider>
    </Router>
  )
}

const Routing = () => {
  const { isRtl } = useContext(AuthContext);
  console.log("isRtl: ", isRtl);
  return (

    <ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
      {/* <ConnectProvider> */}
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/login" element={<Signin />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/otp" element={<SignupOtp />} />

        <Route path="/dashboard" element={<WhatsAppsProvider><Dashboard /></WhatsAppsProvider>} />
        <Route path="/answer" element={<WhatsAppsProvider><Answer /></WhatsAppsProvider>} />

        <Route path="/chatbot" element={<WhatsAppsProvider><Chatbot /></WhatsAppsProvider>} />
        <Route path="/contact" element={<WhatsAppsProvider><Contact /></WhatsAppsProvider>} />
        <Route path="/user" element={<WhatsAppsProvider><User /></WhatsAppsProvider>} />
        <Route path="/broadcast" element={<WhatsAppsProvider><Broadcast /></WhatsAppsProvider>} />
        <Route path="/connection" element={<WhatsAppsProvider><Connection /></WhatsAppsProvider>} />
        <Route path="/price" element={<WhatsAppsProvider><Priceplan /></WhatsAppsProvider>} />
        <Route path="/event" element={<WhatsAppsProvider><Event /></WhatsAppsProvider>} />
        <Route
          path="/tickets"
          element={<WhatsAppsProvider><Inbox /></WhatsAppsProvider>}
        />
        <Route
          path="/tickets/:ticketId"
          element={<WhatsAppsProvider><Inbox /></WhatsAppsProvider>}
        />
      </Routes>
      {/* </ConnectProvider> */}
    </ThemeProvider>
  )
}

root.render(
  <RouteContainer />
);