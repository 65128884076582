import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import * as Yup from "yup";
import { Formik, Field, FieldArray } from "formik";
import api from "../../../core/api";
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Switch from "@material-ui/core/Switch";
import { FormControlLabel } from "@material-ui/core";
import ConfirmationModal from "../global/ConfirmationModal";
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileModal from "./FileModal";

const QueueSchema = Yup.object().shape({
  options: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().min(4, "too short").required("Required"),
      })
    )
    .required("Must have friends"),
});

const useStyles = makeStyles((theme) => ({
  greetingMessage: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    marginRight: theme.spacing(1),
    "& .MuiInputBase-root": {
      background: "#FFF"
    }
  },
  iconBtn: {
    marginRight: '0px !important',
    marginLeft: 15,
  },
  Box: {
    cursor: "pointer",
    alignItems: "center",
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  textFieldOption: {
    width: '200px',
    marginRight: 20
  },
  messageText: {
    color: 'black'
  }
}));

function getStepContent(step, className) {
  return <VerticalLinearStepper chatBotId={step} className={className} />;
}

export default function VerticalLinearStepper(props) {
  const initialState = {
    name: "",
    greetingMessage: "",
    options: [],
  };

  const classes = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(-1);
  const [steps, setSteps] = React.useState(initialState);
  const [loading, setLoading] = React.useState(false);
  const [isStepContent, setIsStepContent] = React.useState(true);
  const [isNameEdit, setIsNamedEdit] = React.useState(null);
  const [isGreetingMessageEdit, setGreetingMessageEdit] = React.useState(null);
  const [selectedQueue, setSelectedQueue] = React.useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [isFileUploadEdit, setFileUploadEdit] = React.useState(null);
  const [showFileUploadModal, setShowFileUploadModal] = React.useState(false);

  const handleSaveBot = async (values) => {
    console.log("option values: ", values);
    try {
      if (props.chatBotId) {
        await api.put(`/chatbot/${props.chatBotId}`, values);
      } else {
        await api.post("/chatbot", values);
      }
      console.log("Bot saved successfully")
      // setActiveStep(-1)
      const { data } = await api.get(`/chatbot/${props.chatBotId}`);

      setSteps(initialState);
      setSteps(data);
      setIsNamedEdit(null);
      setGreetingMessageEdit(null);
      setShowFileUploadModal(false);
      setFileUploadEdit(null);

      setSteps(data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchList = async () => {
        try {
          const { data } = await api.get(`/chatbot/${props.chatBotId}`);
          setSteps(data);
          setLoading(false);
        } catch (err) {
          console.log(err);
        }
      };
      fetchList();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [props.chatBotId]);

  React.useEffect(() => {
    if (activeStep === isNameEdit) {
      setIsStepContent(false);
    } else {
      setIsStepContent(true);
    }
  }, [isNameEdit, activeStep]);

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/chatbot/${queueId}`);
      const { data } = await api.get(`/chatbot/${props.chatBotId}`);
      setSteps(initialState);
      setSteps(data);
      setIsNamedEdit(null);
      setGreetingMessageEdit(null);
      setSteps(data);
      console.log("Queue deleted successfully!");
    } catch (err) {
      console.log(err)
    }
    setSelectedQueue(null);
  };

console.log("chatbotID: ", props.chatBotId);

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${t('chatbots.modal.delete')} ${selectedQueue.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      >
        {t('chatbots.modal.deleteQueueContent')}
      </ConfirmationModal>

      {!loading && (
        <div>
          <Formik
            initialValues={steps}
            validateOnChange={false}
            enableReinitialize={true}
            validationSchema={QueueSchema}
            render={({
              touched,
              errors,
              isSubmitting,
              values,
              handleSubmit,
            }) => (
              <FieldArray name="options">
                {({ push, remove }) => (
                  <>
                    <Stepper
                      nonLinear
                      activeStep={activeStep}
                      orientation="vertical"
                      className={props.className}
                    >
                      {values.options &&
                        values.options.length > 0 &&
                        values.options.map((info, index) => (
                          <Step
                            key={`${info.id ? info.id : index}-options`}
                            onClick={() => setActiveStep(index)}
                          >
                            <StepLabel key={`${info.id}-options`}>
                              {isNameEdit !== index &&
                                steps.options[index]?.name ? (
                                <div
                                  className={classes.greetingMessage}
                                  variant="body1"
                                >
                                  {values.options[index].name}

                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      setIsNamedEdit(index);
                                      setIsStepContent(false);
                                    }}
                                    className={classes.iconBtn}
                                  >
                                    <EditIcon style={{ fontSize: '18px', color: 'rgb(97, 206, 247)' }} />
                                  </IconButton>

                                  <IconButton
                                    onClick={() => {
                                      setSelectedQueue(info);
                                      setConfirmModalOpen(true);
                                    }}
                                    size="small"
                                  >
                                    <DeleteOutline style={{ fontSize: '20px', color: 'rgb(97, 206, 247)' }} />
                                  </IconButton>
                                </div>
                              ) : (
                                <div className={classes.optionContainer}>
                                  <Field
                                    as={TextField}
                                    name={`options[${index}].name`}
                                    variant="outlined"
                                    label={t('chatbots.modal.optionName')}
                                    margin="dense"
                                    color="primary"
                                    disabled={isSubmitting}
                                    value={undefined}
                                    autoFocus
                                    inputProps={{ maxLength: 50 }}
                                    error={Object.keys(errors).length !== 0 && errors.options.length && errors.options[index]?.name ? true : false}
                                    helperText={Object.keys(errors).length !== 0 && errors.options.length && errors.options[index]?.name}
                                    className={classes.textFieldOption}
                                  />

                                  {/* <FormControlLabel
                                    control={
                                      <Field
                                        as={Switch}
                                        color="primary"
                                        name={`options[${index}].isAgent`}
                                        checked={
                                          values.options[index].isAgent || false
                                        }
                                      />
                                    }
                                    label={t('chatbots.modal.agent')}
                                  /> */}

                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      values.options[index].name
                                        ? handleSaveBot(values)
                                        : null
                                    }
                                    disabled={isSubmitting}
                                  >
                                    <SaveIcon style={{ fontSize: '18px', color: 'rgb(97, 206, 247)' }} />
                                  </IconButton>

                                  <IconButton
                                    size="small"
                                    onClick={() => remove(index)}
                                    disabled={isSubmitting}
                                  >
                                    <DeleteOutline style={{ fontSize: '20px', color: 'rgb(97, 206, 247)' }} />
                                  </IconButton>
                                </div>
                              )}
                            </StepLabel>

                            {isStepContent && steps.options[index] && (
                              <StepContent>
                                <>
                                  {isGreetingMessageEdit !== index ? (
                                    <>
                                      <div className={classes.greetingMessage}>
                                        <span className={classes.messageText}>
                                          {values.options[index]?.greetingMessage ? values.options[index]?.greetingMessage : undefined}
                                        </span>

                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            setGreetingMessageEdit(index)
                                          }
                                        >
                                          <EditIcon style={{ fontSize: '18px', color: 'rgb(97, 206, 247)' }} />
                                        </IconButton>
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            setShowFileUploadModal(true)
                                            setFileUploadEdit(index)
                                          }}
                                        >
                                          <CloudUploadIcon style={{ fontSize: '18px', color: 'rgb(97, 206, 247)' }} />
                                        </IconButton>
                                      </div>
                                      <div style={{ paddingLeft: 10 }}>
                                        {values.options[index]?.file ?
                                          (values.options[index].file.split(';') || []).map((fileName, idx) => {
                                            if (idx === values.options[index].file.split(';')) return;
                                            return (
                                              <a
                                                key={idx}
                                                href={`${process.env.REACT_APP_API_URL}/public/${fileName}`}
                                                target='_blank'
                                                className='underline text-[#1d1d1d] text-[12px]'
                                              >
                                                <p>{fileName}</p>
                                              </a>
                                            )
                                          }) :
                                          <span className="text-red-400 text-[12px]">
                                            No attached files on this option
                                          </span>
                                        }
                                      </div>
                                    </>
                                  ) : (
                                    <div className={classes.greetingMessage}>
                                      <div className="flex flex-col w-full justify-end items-end">
                                        <Field
                                          as={TextField}
                                          name={`options[${index}].greetingMessage`}
                                          variant="outlined"
                                          label={t('chatbots.modal.message')}
                                          minRows={3}
                                          margin="dense"
                                          value={values.options[index].greetingMessage || ''}
                                          fullWidth
                                          multiline
                                          error={
                                            touched.greetingMessage &&
                                            Boolean(errors.greetingMessage)
                                          }
                                          helperText={
                                            touched.greetingMessage &&
                                            errors.greetingMessage
                                          }
                                          className={classes.textField}
                                          inputProps={{ maxLength: 200 }}
                                        />
                                        <p className="text-black text-[12px] pr-2">Max Length: 200</p>
                                      </div>

                                      <IconButton
                                        size="small"
                                        onClick={() => handleSaveBot(values)}
                                        disabled={isSubmitting}
                                      >
                                        {" "}
                                        <SaveIcon style={{ fontSize: '18px', color: 'rgb(97, 206, 247)' }} />
                                      </IconButton>
                                    </div>
                                  )}

                                  {getStepContent(info.id, props.className)}
                                </>
                              </StepContent>
                            )}
                          </Step>
                        ))}

                      <Step>
                        <StepLabel>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              cursor: 'pointer',
                              width: '120px'
                            }}
                            onClick={() => {
                              push({
                                name: undefined,
                                greetingMessage: undefined,
                              })
                              console.log("steps values: ", values)
                            }}
                          >
                            <span style={{
                              color: '#FFA500', paddingRight: 5
                            }}>{t('chatbots.modal.optionsAdd')}</span>
                            <AddCircleIcon style={{ fontSize: '20px', color: '#FFA500' }} />
                          </div>
                        </StepLabel>
                      </Step>
                    </Stepper>
                  </>
                )}
              </FieldArray>
            )}
          />
        </div>
      )}

      <FileModal
        open={showFileUploadModal}
        isFileUploadEdit={isFileUploadEdit}
        onClose={() => {
          setShowFileUploadModal(false)
          setFileUploadEdit(null);
        }}
        setUploadFileName={(index, fileName) => {
          const curQueues = steps;
          if (curQueues.options.length && curQueues.options[index]) {
            curQueues.options[index].file = fileName;
            handleSaveBot(curQueues);
          }
        }}
      />
    </div>
  );
}
