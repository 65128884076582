import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import USFlag from '../assets/flag/us.png';
import SAFlag from '../assets/flag/sa.png';
import LogoPNG from '../assets/mowasel-logo.png';
import AuthBgPNG from '../assets/auth/auth_bg.png';
import { AuthContext } from '../core/contexts/AuthProvider';
import { isValidText, isValidEmail } from '../utils/helper';
import { Flag } from './components/Headerbar';
import { useTranslation } from 'react-i18next';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from 'react-toastify';

const Signin = () => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errorType, setErrorType] = useState(null);
  const [error, setError] = useState(null);
  const { loading, user, err, handleLogin, setErr, setIsRtl, isRtl } = useContext(AuthContext);
  const [flagType, setFlagType] = useState('en');

  useEffect(() => {
    const flag = localStorage.getItem('flag');
    if (flag) {
      if (flag === 'en') {
        document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
      } else {
        document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
      }
      setFlagType(flag);
      i18n.changeLanguage(flag);
    } else {
      setFlagType('en');
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
      i18n.changeLanguage('en');
    }
  }, [])

  const handleChangeLang = (lang) => {
    setFlagType(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('flag', lang);
    if (lang === 'en') {
      setIsRtl(false);
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    } else {
      setIsRtl(true);
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    }
  }

  useEffect(() => {
    setErrorType(null);
    setError(null);
  }, [phone, password]);

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
        navigation('/dashboard');

    }
  }, [user])

  function handleSubmit() {
    if (!phone) {
      setErr('Please input the phone number!');
      return;
    }
    if (!isValidText(password)) {
      setErr('Please input the password!');
      return;
    }
    handleLogin({ email: phone, password: password })
  }

  function handleSubmitBySalla() {
    window.location.href = `${process.env.REACT_APP_API_URL}/login`;
  }

  function handleSignup() {
    setErr(null);
    navigation("/register");
  }

  return (
    <>
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="text-white text-lg">Please wait while confirming user Information...</div>
        </div>
      )}
      <div className={`flex flex-row justify-center items-center fixed  space-x-4 ${isRtl ? 'top-10 left-10' : 'top-10 right-10'}`}>
        {flagType === 'sa' ?
          <Flag
            flag={USFlag}
            country={'EN'}
            onClick={() => handleChangeLang('en')}
          /> :
          <Flag
            flag={SAFlag}
            country={'AR'}
            onClick={() => handleChangeLang('sa')}
          />
        }
        <img src={LogoPNG} className='w-[150px]' />
      </div>
      <div className='w-[100vw] h-[100vh] flex flex-row justify-between items-center'>
        <img src={AuthBgPNG} className='h-[100%]' />
        <div className='flex flex-1 h-[100%]'>
          <div className='flex justify-center items-center w-[100%]'>
            <div className='flex flex-col justify-center items-center space-y-4 w-[456px] text-[#1F1F1F]'>
              <div className='text-center w-[100%] !mb-8'>
                <p className='text-[32px] font-[700]'>{t('auth.signin.title')}</p>
                <p className='text-[14px]'>
                  {t('auth.signin.subtitle')}
                </p>
              </div>

              {/* <input
                type='text'
                placeholder={t('auth.signin.placeholder.phone')}
                className='bg-[#EFEFEF] px-4 py-3 rounded-[4px] w-[100%]'
                onChange={(event) => {
                  setPhone(event.target.value)
                  setErr(null)
                }}
                value={phone || ''}
              /> */}
              <PhoneInput
                country={"sa"}
                preferredCountries={["sa", "us", "gb"]}
                value={phone}
                onChange={(phone) => {
                  setPhone(phone);
                  setErr(null);
                }}
                inputClass={"!w-full !bg-[#EFEFEF] !h-[50px] !border-none"}
              />
              <input
                type='password'
                placeholder={t('auth.signin.placeholder.password')}
                className='bg-[#EFEFEF] px-4 py-3 rounded-[4px] w-[100%]'
                onChange={(event) => {
                  event.target.value = event.target.value.trim();
                  setPassword(event.target.value.trim());
                  setErr(null);
                }}
                value={password || ''}
              />
              <button
                className='bg-[#2E3087] rounded-[4px] w-[100%] py-3 text-white text-center'
                onClick={handleSubmit}
              >
                {t('auth.signin.signin')}
              </button>
              <hr className='border-dashed border-slate-500 w-full'></hr>
              <button
                className='bg-[rgb(65,52,126)] rounded-[4px] w-[100%] py-3 text-white text-center'
                onClick={handleSubmitBySalla}
              >
                {t('auth.signin.signinbysalla')}
              </button>
              {err ?
                <div className="w-full p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
                  <span className="font-medium pr-2">Warning!</span>
                  {err}
                </div> : null
              }
              <p className='text-[14px] text-[#7C7C7C] w-[100%] text-center !mt-8'>
                {t('auth.signin.yet')} <a className="underline text-[#2E3087] font-bold cursor-pointer" onClick={handleSignup}>
                  {t('auth.signup.signup')}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Signin;